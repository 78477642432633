import useFetchService from '@/composables/useFetchService';

export default function useSearchService() {
  /********************
   * COMPOSITIONS      *
   ********************/
  const runtimeConfig = useRuntimeConfig();
  const fetchService = useFetchService({ base: runtimeConfig.public.apiUrl });

  /********************
   * FUNCTIONS         *
   ********************/
  function getAccommodations(slug: string, lang: string, params?: any) {
    return fetchService.get(`/search/accommodation/${slug}/`, lang, { params });
  }

  function getAvailabilities(slug: string, lang: string, params: any) {
    return fetchService.get(`/search/availabilities/${slug}/`, lang, { params });
  }

  function getCampsite(slug: string, lang: string, params?: any) {
    return fetchService.get(`/search/campsites/${slug}/`, lang, { params }, 11);
  }

  function getCampsitePreEnter(slug: string, lang: string, params?: any) {
    return fetchService.get(`/search/campsites/${slug}/pre-enter/`, lang, { params }, 2);
  }

  function getSlugByCampingId(campingId: string | number) {
    return fetchService.get(`/search/camping-id/${campingId}/`, null, {});
  }

  function getCampsiteMedia(slug: string, lang: string, params?: any) {
    return fetchService.get(`/search/media/${slug}/`, lang, { params });
  }

  function getCampsites(lang: string, params: any) {
    return fetchService.get('/search/campsites/', lang, { params }, 6);
  }

  function getLiveAvailabilities(slug: string, lang: string, params: any) {
    return fetchService.get(`/search/availabilities/live/${slug}/`, lang, { params }, 2);
  }

  function getClosestAvailableCampsites(slug: string, lang: string, params: any) {
    return fetchService.get(`/search/availabilities/closest/${slug}/`, lang, { params }, 6);
  }

  function getLocation(lang: string, params: any) {
    return fetchService.get('/search/location/', lang, { params }, 2);
  }

  function getMapDetail(slug: string, lang: string, params: any) {
    return fetchService.get(`/search/map/${slug}/`, lang, { params });
  }

  function getSuggestions(lang: string, params: any) {
    return fetchService.get('/search/suggest/', lang, { params }, 2);
  }

  function searchMap(lang: string, params: any) {
    return fetchService.get('/search/map/', lang, { params }, 2);
  }

  return {
    getAccommodations,
    getAvailabilities,
    getCampsite,
    getCampsiteMedia,
    getCampsitePreEnter,
    getCampsites,
    getClosestAvailableCampsites,
    getLiveAvailabilities,
    getLocation,
    getMapDetail,
    getSlugByCampingId,
    getSuggestions,
    searchMap,
  };
}
