/**
  INDEX, CANONICAL AND ALTERNATE RULES

  Definition:
  --------------------------------------------------------------------
  Known parameter:
  - Known parameters are defined in urlParams.json per route
  - e.g: adults oder rating_avg_overall

  Case 1: url includes only known parameters
  --------------------------------------------------------------------
  - page: NOINDEX
  - canonical: as URL including parameters
  - alternate: as canonical

  Case 2: url includes only unknown parameters
  --------------------------------------------------------------------
  - page: INDEX
  - canonical: as URL excluding parameters
  - alternate: as canonical

  Case 3: url includes known and unknown parameters
  --------------------------------------------------------------------
  - page: NOINDEX
  - canonical: URL excluding unknown parameters (with known parameters)
  - alternate: as canonical

  --------------------------------------------------------------------
  THE CONTENT SERVICE SHOULD ALWAYS USE SAME URL AS CANONICAL IS
  --------------------------------------------------------------------

*/
import urlParams from '@/assets/json/urlParams.json';
import indexRouteBlackList from '@/assets/json/indexRouteBlackList';

import type { RouteMetaData } from '@/types';

export default function useSeo() {
  /********************
   * COMPOSITIONS      *
   ********************/
  // const router = useRouter();
  const appStore = useAppStore();
  const campsiteStore = useCampsiteStore();
  const contentStore = useContentStore();
  const error = useError();
  const getRouteBaseName = useRouteBaseName();
  const jsonLdStore = useJsonLdStore();
  const route = useRoute();
  const runtimeConfig = useRuntimeConfig();
  const seoStore = useSeoStore();
  const switchLocalePath = useSwitchLocalePath();
  const { $getTranslatedWordCampsite, $setPageViewPending, $translationStartsWithWordCampsite, $trackPageView, $setPageType } = useNuxtApp();
  const { $pgettext, interpolate } = useGettext();
  const { locale, locales } = useI18n();

  /********************
   * REFS & VARS       *
   ********************/
  const routeBaseName = computed(() => {
    return getRouteBaseName(route);
  });

  /*
  Return $route query params separated by known/unknown
  */
  const separatedQueryParams = computed(() => {
    const isCountrySearch = routeBaseName.value === RBN_COUNTRY_SEARCH;
    const isCitySearch = routeBaseName.value === RBN_CITY_SEARCH;
    const isFederalState = routeBaseName.value === RBN_FEDERAL_STATE_SEARCH;
    const isRegionSearch = routeBaseName.value === RBN_REGION_SEARCH;

    const key = isCountrySearch || isCitySearch || isFederalState || isRegionSearch ? RBN_SEARCH : routeBaseName.value;

    // get valid params defined for route
    const validParams = urlParams[key] || [];
    const queryKeys = Object.keys(route.query);

    const knownParams: string[] = [];
    const unknownParams: string[] = [];

    queryKeys.forEach((key) => {
      return validParams.includes(key) ? knownParams.push(key) : unknownParams.push(key);
    });

    return {
      knownParams,
      unknownParams,
    };
  });

  const title = computed(() => {
    if (contentStore.meta?.find(meta => meta.name === 'title')) {
      const content = contentStore.meta.find(meta => meta.name === 'title').content;
      return seoStore.dynamicMetaParam ? interpolate(content, seoStore.dynamicMetaParam, true) : content;
      // return seoStore.dynamicMetaParam ? $gettext(content, seoStore.dynamicMetaParam, true) : content;
    }

    let translated = '';
    let metaDataKey = routeBaseName.value;
    if (!metaDataKey || (error.value?.statusCode || 200) >= 400) {
      metaDataKey = 'error';
    }

    const metaAtRouteName: RouteMetaData = metaData[metaDataKey];
    if (!metaAtRouteName) {
      return false;
    }

    try {
      if (seoStore.dynamicMetaParam && typeof seoStore.dynamicMetaParam === 'object') {
        const paramKeyArr = Object.keys(seoStore.dynamicMetaParam).sort();
        const paramKey = paramKeyArr.sort().join(' ');

        if (!paramKey) {
          console.warn('paramKey for title is empty');
        }

        if (paramKey && metaAtRouteName.defaultTitle[paramKey]) {
          translated = $pgettext(
            metaAtRouteName.defaultTitle[paramKey]?.ctx,
            metaAtRouteName.defaultTitle[paramKey]?.key,
          );
          if (
            routeBaseName.value === RBN_CAMPSITE_DETAIL
            || routeBaseName.value === 'campsite-identifier-reviews'
            || routeBaseName.value === 'campsite-identifier-reviews-id'
          ) {
            return replaceCampsitePrefix(translated);
          } else {
            return interpolate(translated, seoStore.dynamicMetaParam, true);
          }
        } else {
          if (runtimeConfig.public.nodeEnv === 'development') {
            console.warn(`could not find title for key ${paramKey} for ${routeBaseName?.value}`);
          }
        }
      }

      if (seoStore.dynamicMetaParam && typeof seoStore.dynamicMetaParam !== 'object') {
        translated = $pgettext(metaAtRouteName.defaultTitle?.ctx, metaAtRouteName.defaultTitle?.key);
        return interpolate(translated, { dynamicParam: seoStore.dynamicMetaParam }, true);
      }
    } catch (_e) {
      // error is okay
    }

    if (metaAtRouteName.defaultTitle && metaAtRouteName.defaultTitle?.ctx) {
      return $pgettext(metaAtRouteName.defaultTitle?.ctx, metaAtRouteName.defaultTitle?.key);
    }
    // return $pgettext(metaAtRouteName.defaultTitle?.ctx, metaAtRouteName.defaultTitle?.key);
  });

  const description = computed(() => {
    if (contentStore.meta?.find(meta => meta.name === 'description')) {
      const content = contentStore.meta.find(meta => meta.name === 'description').content;
      return seoStore.dynamicMetaParam ? interpolate(content, seoStore.dynamicMetaParam, true) : content;
      // return seoStore.dynamicMetaParam ? $gettext(content, seoStore.dynamicMetaParam, true) : content;
    }

    if (!routeBaseName.value) {
      return;
    }

    const metaAtRouteName = metaData[routeBaseName.value];
    if (!metaAtRouteName || !metaAtRouteName.defaultDescription) {
      return false;
    }

    let translated;
    if (metaAtRouteName.defaultDescription?.ctx && metaAtRouteName.defaultDescription?.key) {
      translated = $pgettext(metaAtRouteName.defaultDescription?.ctx, metaAtRouteName.defaultDescription?.key);
    }

    if (seoStore.dynamicMetaParam && typeof seoStore.dynamicMetaParam === 'object') {
      if (
        routeBaseName.value === RBN_CAMPSITE_DETAIL
        || routeBaseName.value === 'campsite-identifier-reviews'
        || routeBaseName.value === 'campsite-identifier-reviews-id'
      ) {
        const description = seoStore.dynamicMetaParam?.custom_description || translated;
        return replaceCampsitePrefix(description);
      } else {
        // https://campinginfo.atlassian.net/browse/NG-1908
        if (
          [RBN_CITY_SEARCH, RBN_COUNTRY_SEARCH, RBN_FEDERAL_STATE_SEARCH, RBN_REGION_SEARCH].includes(
            routeBaseName.value,
          )
        ) {
          const resultCount = seoStore.dynamicMetaParam?.resultCount;
          if (resultCount && resultCount > 5 && !seoStore.distance?.value) {
            translated = $pgettext(
              metaAtRouteName.defaultDescription?.['resultCount>5']?.ctx,
              metaAtRouteName.defaultDescription?.['resultCount>5']?.key,
            );
          } else {
            translated = $pgettext(
              metaAtRouteName.defaultDescription?.['resultCount<=5']?.ctx,
              metaAtRouteName.defaultDescription?.['resultCount<=5']?.key,
            );
          }
        }
        return interpolate(translated, seoStore.dynamicMetaParam, true);
      }
    }

    if (seoStore.dynamicMetaParam && typeof seoStore.dynamicMetaParam !== 'object') {
      return interpolate(translated, { dynamicParam: seoStore.dynamicMetaParam }, true);
    }

    return translated;
  });

  const canonicalLink = computed(() => {
    if (error.value) {
      return {};
    }
    const sortedQuery = cleanAndSortParams(routeBaseName.value, route.query);
    return {
      vmid: 'canonical',
      rel: 'canonical',
      href: `${getHREF().split('?')[0]}${sortedQuery}`,
    };
  });

  const alternateLinks = computed(() => {
    if (error.value) {
      return [];
    }

    const languages = locales.value;
    const sortedQuery = cleanAndSortParams(routeBaseName.value, route.query);
    const localeAlternateLinks = languages.map((lang) => {
      return {
        vmid: `alternate-${lang.code}`,
        rel: 'alternate',
        href: `${getHREF(lang.code).split('?')[0]}${sortedQuery}`,
        hreflang: lang.code,
      };
    });
    return localeAlternateLinks;
  });

  const indexValueForView = computed(() => {
    if (runtimeConfig.public.metaNoindex as boolean) {
      return 'NOINDEX';
    }

    // initial unset of no-index
    let indexType = 'INDEX';

    const { matched, name, query, path } = route;
    if (!name || (error.value?.statusCode || 200) >= 400) {
      return 'NOINDEX';
    }

    if (seoStore.indexPage === false) {
      return 'NOINDEX';
    }

    if (isDynamicPage(routeBaseName.value)) {
      const routeFound = appStore.routes.some((routesByLanguage) => {
        const matchedRoutes = routesByLanguage.filter((route) => {
          const encodedUrl = route.url
            .split('/')
            .map(part => encodeURIComponent(part))
            .join('/');
          return route.url === path || encodedUrl === path;
        });
        return matchedRoutes.length > 0;
      });
      if (!routeFound) {
        return 'NOINDEX';
      }
    }

    const queryKeys = Object.keys(query || {});

    const index = queryKeys.indexOf('ciskipcache');

    if (index > -1) {
      delete query.ciskipcache;
      queryKeys.splice(index, 1);
    }

    indexRouteBlackList.some((routeName) => {
      const parentFound = (matched && matched.some(route => route.name === routeName)) || false;
      const directMatch = name === routeName || routeBaseName.value === routeName || false;

      if (directMatch || parentFound) {
        indexType = 'NOINDEX';
        return true;
      } else {
        const { knownParams } = separatedQueryParams.value;

        if (knownParams.length) {
          indexType = 'NOINDEX';
          return true;
        }
      }
      return false;
    });
    return indexType;
  });

  const openGraph = computed(() => {
    const openGraphArr = [
      {
        vmid: 'og:locale',
        property: 'og:locale',
        content: locale.value,
      },
      {
        vmid: 'og:url',
        property: 'og:url',
        content: getHREF(),
      },
      {
        vmid: 'og:site_name',
        property: 'og:site_name',
        content: 'camping.info',
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: `${title.value} - camping.info`,
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: description.value,
      },
    ];

    // 1. try to get og:image from meta content module
    let ogImage = contentStore.meta?.find(meta => meta.name === 'og:image');

    // 2. (CPDP only) if no og:image is set, use campsite image as og:image
    if (!ogImage && routeBaseName.value === RBN_CAMPSITE_DETAIL && campsiteStore.campsite?.meta?.og_image) {
      ogImage = {
        name: 'og:image',
        content: campsiteStore.campsite.meta.og_image,
      };
    }

    // 3. (dynamic pages only) if no og:image is set, use first fullwidthimage as og:image
    if (!ogImage && isDynamicPage(routeBaseName.value)) {
      contentStore.contents.forEach((slot) => {
        (slot.contents || [])
          .filter(c => c.content_type === 'fullwidthimage')
          .forEach((content) => {
            if (!ogImage) {
              ogImage = {
                name: 'og:image',
                content: content.fields.big_image.url,
              };
            }
          });
      });
      if (!ogImage) {
        ogImage = {
          name: 'og:image',
          content: '/img/default_og_image.jpg',
        };
      }
    }

    if (ogImage) {
      openGraphArr.push({
        vmid: 'og:image',
        property: 'og:image',
        content: ogImage.content,
      });
    }

    if (routeBaseName.value === RBN_CAMPSITE_DETAIL) {
      openGraphArr.push({
        vmid: 'og:type',
        property: 'og:type',
        content: 'website',
      });
    }

    const languages = locales.value;
    if (error.value?.statusCode !== 404) {
      languages.forEach((lang) => {
        if (lang !== locale.value) {
          openGraphArr.push({
            vmid: `og:locale:alternate-${lang.code}`,
            property: 'og:locale:alternate',
            content: lang.code,
          });
        }
      });
    }
    return openGraphArr;
  });

  const defaultMetaData = computed(() => {
    return [
      {
        hid: 'description',
        name: 'description',
        content: description.value,
      },
      {
        hid: 'robots',
        name: 'robots',
        content: indexValueForView.value,
      },
      {
        'hid': 'http-equiv-language',
        'http-equiv': 'language',
        'content': locale.value.toUpperCase(),
      },
      ...openGraph.value,
    ];
  });
  /********************
   * WATCHER           *
   ********************/
  watch(() => route.fullPath, seoOnRouteChange, { immediate: true });

  /********************
   * FUNCTIONS         *
   ********************/
  function seoOnRouteChange() {
    seoCheckMetaData();

    if (import.meta.client) {
      $setPageViewPending();
      nextTick(() => {
        $setPageType({ type: routeBaseName.value || '', language: locale.value });
      });
    }

    // do not track PV on map with no query params
    if (routeBaseName.value === RBN_SEARCH_MAP && Object.keys(route.query).length === 0) {
      return;
    }

    if (import.meta.client) {
      nextTick(() => {
        $trackPageView();
      });
    }
  }

  function replaceCampsitePrefix(translation: string) {
    if (!seoStore.dynamicMetaParam?.campsite_name) {
      return '';
    }

    if ($translationStartsWithWordCampsite(seoStore.dynamicMetaParam.campsite_name)) {
      const regEx = new RegExp(`^(${$getTranslatedWordCampsite()}) `, 'i');
      translation = translation.replace(regEx, '');
    }
    return interpolate(translation, seoStore.dynamicMetaParam, true);
    // return $gettext(translation, seoStore.dynamicMetaParam, true);
  }

  function seoCheckMetaData() {
    const metaAtRouteName = routeBaseName.value ? metaData[routeBaseName.value] : null;

    const error: { metaData?: string; title?: string; description?: string; h1?: string } = {};

    if (!metaAtRouteName && runtimeConfig.public.nodeEnv === 'development') {
      error.metaData = `[SEO ERROR] No default meta data set for ${routeBaseName.value}.`;
    }

    if (metaAtRouteName && !metaAtRouteName.defaultTitle && runtimeConfig.public.nodeEnv === 'development') {
      error.title = `[SEO ERROR] No default meta title set for ${routeBaseName.value}.`;
    }

    if (metaAtRouteName && !metaAtRouteName && runtimeConfig.public.nodeEnv === 'development') {
      error.description = `[SEO ERROR] No default meta description set for ${routeBaseName.value}.`;
    }

    if (metaAtRouteName && !metaAtRouteName.defaultH1 && runtimeConfig.public.nodeEnv === 'development') {
      error.h1 = `[SEO ERROR] No default H1 set for ${routeBaseName.value}.`;
    }
  }

  function getHREF(lang = locale.value) {
    let translatedUrl;
    const isDynamic = isDynamicPage(routeBaseName.value);

    if (!isDynamic) {
      translatedUrl = switchLocalePath(lang);
    }

    /**
     * https://camparound.atlassian.net/browse/CIV2-1653
     * In addition to the translation of multi level country searches (aka ":searchTerms"),
     * we also need to translate the parent slug (aka "country") and not only the current slug
     */
    if (
      Object.prototype.hasOwnProperty.call(seoStore.alternateTags, 'current')
      && Object.prototype.hasOwnProperty.call(seoStore.alternateTags, 'parent')
    ) {
      /**
       * https://camparound.atlassian.net/browse/CIV2-1671
       * We need to do a string replace at a certain position of the url
       * to avoid translating an already translated part.
       */
      // encodeURIComponent
      // decodeURIComponent
      const translatedWithoutQuery = translatedUrl?.split('?');
      const separated = translatedWithoutQuery?.[0].split('/');
      const currentLang = locale.value;

      if (translatedWithoutQuery && separated) {
        const region = decodeURIComponent(separated[separated.length - 1]);
        const country = decodeURIComponent(separated[separated.length - 2]);
        // translate region
        if (region) {
          separated[separated.length - 1] = encodeURIComponent(
            region.replace(seoStore.alternateTags.current[currentLang], seoStore.alternateTags.current[lang]),
          );
        }
        // translate country
        if (country) {
          separated[separated.length - 2] = encodeURIComponent(
            country.replace(seoStore.alternateTags.parent[currentLang], seoStore.alternateTags.parent[lang]),
          );
        }

        if (translatedWithoutQuery[1]) {
          translatedUrl = `${separated.join('/')}?${translatedWithoutQuery[1]}`;
        } else {
          translatedUrl = separated.join('/');
        }
      }
    } else {
      // fallback for single level urls without a parent slug
      if (isDynamic) {
        if (seoStore.alternateTags && seoStore.alternateTags[lang]) {
          translatedUrl = `/${lang}${seoStore.alternateTags[lang]}`;
        } else {
          translatedUrl = getDynamicAlternateForLang(route.path, lang, appStore.routes);
        }
      }

      if (translatedUrl) {
        translatedUrl = translatedUrl.replace(
          seoStore.alternateTags[locale.value],
          encodeURIComponent(seoStore.alternateTags[lang]),
        );
      }
    }

    return `${runtimeConfig.public.baseUrl}${translatedUrl}`;
  }

  useHead({
    titleTemplate: () => (title.value ? `${title.value} - camping.info` : 'camping.info'),
    meta: () => defaultMetaData.value,
    htmlAttrs: {
      lang: () => locale.value,
    },
    link: () => [canonicalLink.value, ...alternateLinks.value],
    script: () => [...jsonLdStore.faqJsonLd],
  });

  return {
    metaData,
  };
}
