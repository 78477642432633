import dayjs from '@/utils/day';

const WEEKDAYS = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];
const UNITS = {
  d: 'day',
  w: 'week',
  m: 'month',
};

const getTodaysWeekday = () => {
  const d = dayjs();
  return d.weekday() + d.startOf('week').day();
};

const parseDuration = (dur) => {
  const m = dur.match(/^(\d+)([dwm])$/);
  if (!m) {
    return false;
  }
  const n = parseInt(m[1], 10);
  return [n, UNITS[m[2]]];
};

const addDuration = (dur, start = null, dayOffset = 0) => {
  let d = parseDuration(dur);
  if (!d) {
    return false;
  }
  if (!start) {
    start = dayjs();
  }

  const isDay = d[1] === 'day';
  d = start.add(d[0], d[1]);
  if (isDay && dayOffset !== 0) {
    d = d.add(dayOffset, 'day');
  }
  return d;
};

const parseStart = (start, pre) => {
  let idx = WEEKDAYS.indexOf(start);
  if (idx === -1) {
    const d = addDuration(start);
    if (!d) {
      return false;
    }
    return addDuration(pre, d);
  }
  // start = start.add(parseInt(dates[1], 10), 'week');
  const todaysWeekday = getTodaysWeekday();
  if (idx <= todaysWeekday) {
    idx += 7;
  }
  idx -= todaysWeekday;
  return addDuration(pre, dayjs().add(idx, 'day'));
};

export const computeDyndates = (dyndates) => {
  /*
  dyndates=<start>,<pre>,<duration>

  start=<mo|tu|we|th|fr|sa|su>|<number><d|w|m>
  pre=<number><d|w|m>
  duration=<number><d|w|m>

  Beispiele:
  ?dyndates=su,0d,2w
  => Start wäre nächsten Sonntag (Wenn heute Sonntag ist, gilt der darauffolgende), Ende wäre der Sonntag zwei Wochen später

  ?dyndates=su,1w,1w
  => Start wäre übernächsten Sonntag (Wenn heute Sonntag ist, gilt der zweite darauffolgende), Ende wäre der Sonntag eine Woche später

  ?dyndates=2d,0d,1w
  => Start wäre Übermorgen, Ende wäre eine Woche danach

  ?dyndates=1m,3d,4d
  => Start wäre in einem Monat und 3 tagen, Ende wäre 3 tage danach
  */
  if (!dyndates) {
    return false;
  }
  const dates = dyndates.split(',');
  if (dates.length !== 3) {
    console.warn(`Illegal dyndates: ${dyndates}.`);
    return false;
  }
  if (!dates[1].match(/^\d+[dwm]$/)) {
    console.warn(`Illegal dyndates: ${dyndates}.`);
    return false;
  }
  if (!dates[2].match(/^\d+[dw]$/)) {
    console.warn(`Illegal dyndates: ${dyndates}.`);
    return false;
  }
  const start = parseStart(dates[0], dates[1]);
  if (start === false) {
    console.warn(`Illegal dyndates: ${dyndates}.`);
    return false;
  }
  const end = addDuration(dates[2], start, -1);
  if (end === false) {
    console.warn(`Illegal dyndates: ${dyndates}.`);
    return false;
  }
  return { from: start.format('YYYY-MM-DD'), until: end.format('YYYY-MM-DD') };
};
