import { defineStore, acceptHMRUpdate } from 'pinia';
import { equals } from 'ramda';

import type { Location } from '@/types';

export type SearchOpenType = 'location' | 'date' | 'traveller' | 'accommodation' | null;

export const useSearchStore = defineStore('search', () => {
  /********************
   * COMPOSITIONS      *
   ********************/
  const appStore = useAppStore();
  const backendStore = useBackendStore();
  const searchService = useSearchService();
  const { setStatusError, setStatusLoading, setStatusSuccess } = useLoadingStore();

  /********************
   * REFS & VARS       *
   ********************/
  const asTooltipClosed = ref(false);
  const flexAvailabilities = shallowRef<Record<string, any>>({});
  const location = ref<Location | null>(null);
  const mapBounds = ref(null);
  const mapResult = ref(null);
  const mapZoomLevel = ref(null);
  const resultAvailabilityCount = ref(null);
  const resultCurrentPage = ref(1);
  const resultItems = shallowRef([]);
  const resultLimit = ref(15);
  const resultOffset = ref(0);
  const resultVisiblePagerButtons = ref(4);
  const searchCollapsed = ref(false);
  const searchOpenType = ref<SearchOpenType>(null);
  const searchResult = ref(null);
  const searchParams = ref({
    acco_type: null,
    adults: 2,
    children: null,
    flex: 3,
    from: null,
    q: '',
    until: null,
    discounts: [],
  });
  const searchTouched = ref(true);
  const searchTouchedDetails = ref([]);
  const selectedFilter = ref<Record<string, any>>({});
  const selectedFilterGroup = ref<string | null>(null);
  const serpScrollTop = ref(true);
  const suggestions = shallowRef({ locations: [], campsites: [] });
  const showOnlyCampsitesWithAvailabilitiesOnMap = ref(true);
  const lastSerpQuery = ref<object | null>(null);
  const lastSerpRedirect = ref(false);

  let cachedMapQuery: any = null;
  let cachedMapString: any = null;
  let cachedSERPString: any = null;
  let cachedSuggestionQuery;

  const searchParamsWithoutNulls = computed(() => {
    return Object.fromEntries(Object.entries(searchParams.value).filter(([_, v]) => v !== null));
  });

  const filterCount = computed(() => {
    return Object.keys(selectedFilter.value).length;
  });

  const mapResultCount = computed(() => {
    return mapResult.value?.count || 0;
  });

  const mapResultItems = computed(() => {
    return mapResult.value?.results || [];
  });

  const resultCount = computed(() => {
    return searchResult.value?.count || 0;
  });

  const resultMeta = computed(() => {
    return searchResult.value?.meta || {};
  });

  const resultTotalPages = computed(() => {
    if (!searchResult.value) {
      return 0;
    }
    return searchResult.value?.count === 0 ? 0 : Math.max(1, Math.ceil(searchResult.value.count / resultLimit.value));
  });

  const getLocationSlug = computed(() => {
    return (locationType: string) => _getLocationSlug(location.value, locationType);
  });

  const isCampingCardSearch = computed(() => {
    if (!searchParams.value?.discounts) {
      return false;
    }
    return searchParams.value?.discounts?.includes(DISCOUNT_TYPE_CAMPING_CARD);
  });
  /********************
   * FUNCTIONS         *
   ********************/
  const _getEnSlug = (loc) => {
    if (!loc.slugs) {
      return null;
    }
    return loc.slugs.en || null;
  };

  const _getLocationSlug = (loc, locationType: string) => {
    if (!loc) {
      return null;
    }
    if (loc.type === locationType) {
      return _getEnSlug(loc);
    }
    if (!loc[locationType]) {
      return null;
    }
    return _getEnSlug(loc[locationType]);
  };

  const sortedByTopPlacement = (items) => {
    const sortedItems = [];
    const bottomItems = [];
    let topCounter = 0;
    items.forEach((item) => {
      if (item.is_top) {
        topCounter += 1;
        if (topCounter < 4) {
          sortedItems.push(item);
        } else {
          bottomItems.push(item);
        }
      } else {
        sortedItems.push(item);
      }
    });
    return [...sortedItems, ...bottomItems];
  };

  async function getSuggestions({
    lang,
    query,
    forceUpdate = false,
  }: {
    lang: string;
    query: any;
    forceUpdate?: boolean;
  }) {
    if (cachedSuggestionQuery === query && !forceUpdate) {
      return;
    }
    cachedSuggestionQuery = query;

    setStatusLoading('getSuggestions');

    try {
      const response = await searchService.getSuggestions(lang, { q: query });
      const payload = response.length === 0 ? [] : response;

      if (payload.campsites) {
        payload.campsites.forEach((campsite) => {
          campsite.icon = fixImageUrl(backendStore.url, campsite.icon);
        });
      }

      suggestions.value = payload;

      setStatusSuccess('getSuggestions');
      return response;
    } catch (error) {
      setStatusError({ name: 'getSuggestions', error });
      throw error;
    }
  }

  async function getMapSearchResults({ lang, query }: { lang: string; query: any }) {
    const searchString = query.federal_state || query.region || query.country || query.q || '';
    const newQuery = {
      ...query,
      tns: cachedMapString !== searchString,
    };

    if (equals(newQuery, cachedMapQuery)) {
      return mapResult.value;
    }

    location.value = null;
    setStatusLoading('getMapSearchResults');
    cachedMapQuery = newQuery;
    cachedMapString = searchString;

    try {
      const response = await searchService.searchMap(lang, newQuery);

      // Query changed during loading of backend data -> we want to return the old data
      if (!equals(newQuery, cachedMapQuery)) {
        setStatusSuccess('getMapSearchResults');
        return mapResult.value;
      }

      mapResult.value = response;
      location.value = response.meta?.location || null;
      setStatusSuccess('getMapSearchResults');
      return response;
    } catch (error) {
      setStatusError({ name: 'getMapSearchResults', error });
      throw error;
    }
  }

  async function getSearchResults({ lang, query }: { lang: string; query: any }) {
    const searchString = htmlEscape(query.federal_state || query.region || query.country || query.q || '');
    // in case there are no url query params, take the one from store
    const newQuery = {
      ...query,
      limit: query.limit || resultLimit.value,
      offset: query.offset || resultOffset.value,
      tns: cachedSERPString !== searchString,
    };

    if (newQuery.q) {
      newQuery.q = newQuery.q.trim();
    }

    const queryHasNotChanged = () => {
      const tmpNewQuery = { ...newQuery };
      const tmpLastSerpQuery = { ...lastSerpQuery.value };
      delete tmpNewQuery.tns;
      delete tmpLastSerpQuery.tns;

      return equals(tmpNewQuery, tmpLastSerpQuery);
    };

    if (queryHasNotChanged()) {
      location.value = searchResult.value?.meta?.location || null;
      return searchResult.value;
    }

    location.value = null;
    setStatusLoading('getSearchResults');

    try {
      lastSerpQuery.value = { ...newQuery };

      cachedSERPString = searchString;
      const response = await searchService.getCampsites(lang, newQuery);

      const currentPage = newQuery.offset / newQuery.limit + 1;
      if (currentPage < 1000) {
        resultVisiblePagerButtons.value = 4;
      }
      if (currentPage >= 1000) {
        resultVisiblePagerButtons.value = 3;
      }
      if (currentPage >= 10000) {
        resultVisiblePagerButtons.value = 2;
      }

      resultCurrentPage.value = currentPage;

      if (resultCurrentPage.value === 1) {
        resultItems.value = sortedByTopPlacement(response.results);
      } else {
        resultItems.value = response.results;
      }

      resultLimit.value = newQuery.limit;

      searchResult.value = response;
      resultAvailabilityCount.value = response.count_available;

      location.value = response.meta?.location || null;
      setStatusSuccess('getSearchResults');
      return response;
    } catch (error) {
      setStatusError({ name: 'getSearchResults', error });
      throw error;
    }
  }

  async function getAvailabilities({ slug, lang, query }: { slug: string; lang: string; query: any }) {
    // delete null or 'all' keys
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    Object.keys(query).forEach(key => (!query[key] || query[key] === 'all') && delete query[key]);
    setStatusLoading('getAvailabilities');
    location.value = null;

    try {
      const response = await searchService.getAvailabilities(slug, lang, query);
      flexAvailabilities.value = { ...flexAvailabilities.value, [slug]: response };

      location.value = response.meta?.location || null;
      setStatusSuccess('getAvailabilities');
      return response;
    } catch (error) {
      setStatusError({ name: 'getAvailabilities', error });
      throw error;
    }
  }

  async function getLocationSearchResults({ q, lang }: { q: any; lang: string }) {
    setStatusLoading('getLocationSearchResults');

    try {
      const response = await searchService.getLocation(lang, { q });
      setStatusSuccess('getLocationSearchResults');
      return !response || !response.type ? null : response;
    } catch (error) {
      setStatusError({ name: 'getLocationSearchResults', error });
      throw error;
    }
  }

  function setMapSettings({ bounds, zl }) {
    mapBounds.value = bounds;
    mapZoomLevel.value = zl;
  }

  function setSearchTouchedDetails(value) {
    if (!searchTouchedDetails.value.includes(value)) {
      searchTouchedDetails.value = [...searchTouchedDetails.value, value];
    }
  }

  function setSearchParams(value) {
    searchParams.value = value;
    if (searchParams.value.discounts === null || searchParams.value.discounts === undefined) {
      searchParams.value.discounts = [];
    } else if (searchParams.value.discounts && typeof searchParams.value.discounts === 'string') {
      searchParams.value.discounts = [searchParams.value.discounts];
    }
  }

  function redoLastSearch(locale: string) {
    if (!appStore.isInitialRequest || !lastSerpQuery.value) {
      return null;
    }
    /*
    #######################################
    ### t = tracking mode               ###
    #######################################
    TRACKING_MODE_NORMAL = '0'
    TRACKING_MODE_ONLY_TRACKING = '1'
    TRACKING_MODE_NO_TRACKING = '2'
    */
    searchService.getCampsites(locale, { ...lastSerpQuery.value, t: '1' });
    appStore.isInitialRequest = false;
  }

  return {
    asTooltipClosed,
    filterCount,
    flexAvailabilities,
    getAvailabilities,
    getLocationSearchResults,
    getLocationSlug,
    getMapSearchResults,
    getSearchResults,
    getSuggestions,
    isCampingCardSearch,
    lastSerpQuery,
    lastSerpRedirect,
    location,
    mapBounds,
    mapResult,
    mapResultCount,
    mapResultItems,
    mapZoomLevel,
    redoLastSearch,
    result: searchResult,
    resultAvailabilityCount,
    resultCount,
    resultCurrentPage,
    resultItems,
    resultLimit,
    resultMeta,
    resultOffset,
    resultTotalPages,
    resultVisiblePagerButtons,
    searchCollapsed,
    searchOpenType,
    searchParams,
    searchParamsWithoutNulls,
    searchTouched,
    searchTouchedDetails,
    selectedFilter,
    selectedFilterGroup,
    serpScrollTop,
    setMapSettings,
    setSearchParams,
    setSearchTouchedDetails,
    showOnlyCampsitesWithAvailabilitiesOnMap,
    suggestions,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSearchStore, import.meta.hot));
}
