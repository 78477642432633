import useFetchService from '@/composables/useFetchService';

export default function useCampsiteService() {
  /********************
   * COMPOSITIONS      *
   ********************/
  const runtimeConfig = useRuntimeConfig();
  const fetchService = useFetchService({ base: runtimeConfig.public.apiUrl });

  /********************
   * FUNCTIONS         *
   ********************/
  function getRecommendations(slug: string, lang: string) {
    return fetchService.get(`/campsites/recommendations/${slug}/`, lang, {}, 2);
  }

  return {
    getRecommendations,
  };
}
