import { defineStore, acceptHMRUpdate } from 'pinia';

export const useBackendStore = defineStore('backend', () => {
  type Status = {
    statusCode: number;
    message: string;
  };

  /********************
   * COMPOSITIONS      *
   ********************/
  const runtimeConfig = useRuntimeConfig();

  /********************
   * REFS & VARS       *
   ********************/
  let baseDomain = 'camping.info';
  const baseDomainParts = runtimeConfig.public.apiUrl.split('.');

  const len = baseDomainParts.length;
  if (len > 2) {
    baseDomain = `${baseDomainParts[len - 2]}.${baseDomainParts[len - 1]}`;
  }

  const fetchStatus = ref<Status>({ statusCode: 200, message: '' });
  const url = shallowRef(runtimeConfig.public.apiUrl);

  const fetchFailed = computed<boolean>(() => {
    return fetchStatus.value.statusCode >= 400 || false;
  });

  /********************
   * FUNCTIONS         *
   ********************/
  function setBackendUrl({ url: backendUrl }: { url: string }) {
    if (backendUrl && backendUrl.match(/^[a-z0-9_-]+$/i)) {
      url.value = `https://${backendUrl}.${baseDomain}`;
    }
  }

  function setFetchStatus({ statusCode, message }: Status) {
    fetchStatus.value = { statusCode, message };
  }

  function resetFetchStatus() {
    fetchStatus.value = { statusCode: 200, message: '' };
  }

  return {
    fetchFailed,
    fetchStatus,
    resetFetchStatus,
    setBackendUrl,
    setFetchStatus,
    url,
  };
});

export type BackendStore = ReturnType<typeof useBackendStore>;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBackendStore, import.meta.hot));
}
