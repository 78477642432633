import { loadAwin, loadGoogleAnalytics, loadFacebook, loadHotjar, loadOutbrain, loadTaboola } from '@/utils/tracking';
import { loadMoli } from '@/utils/h5v';

export default defineNuxtPlugin({
  name: 'loadExternalScripts',
  parallel: true,
  setup() {
    const runtimeConfig = useRuntimeConfig();

    let consentReadyCalled = false;

    // @ts-check
    window.onConsentReady = () => {
      onConsentReady();
    };

    // @ts-check
    if (window.__consentIsReady) {
      onConsentReady();
    }

    function onConsentReady() {
      if (consentReadyCalled) {
        return;
      }
      consentReadyCalled = true;

      // @ts-check
      if (window.__consents?.GA === true) {
        loadGoogleAnalytics();
      }

      // @ts-check
      if (window.__consents?.HOTJAR === true) {
        loadHotjar();
      }

      // @ts-check
      if (window.__consents?.TABOOLA === true) {
        loadTaboola();
      }

      // @ts-check
      if (window.__consents?.OUTBRAIN === true) {
        loadOutbrain();
      }

      // @ts-check
      if (window.__consents?.FACEBOOK === true) {
        loadFacebook();
      }

      // @ts-check
      if (window.__consents?.AWIN === true) {
        loadAwin();
      }

      if (runtimeConfig.public.appVersion !== 'local') {
        loadMoli(runtimeConfig.public.h5vMoliUrl as string);
      }
    }
  },
});
