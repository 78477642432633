<script setup lang="ts">
export type Error = {
  url: string;
  statusCode: number;
  statusMessage: string;
  message: string;
  description: string;
  stack: string;
  data: unknown;
};

const props = defineProps<{
  error: Error;
}>();
</script>

<template>
  <NuxtLayout
    name="default"
    :bg-gray="true"
  >
    <CiErrorView :error="props.error" />
  </NuxtLayout>
</template>

<style></style>
