import qs from 'qs';
import type { RouterOptions } from '@nuxt/schema';

const delayedScrollValues = (params: { el?: any; top?: number; left?: number; behavior?: 'smooth' }, timeout = 0) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(params);
    }, timeout);
  });
};

export default <RouterOptions>{
  scrollBehavior(to, from, savedPosition) {
    const getRouteBaseName = useRouteBaseName();

    const isCPDP = (route) => {
      return getRouteBaseName(route) === RBN_CAMPSITE_DETAIL;
    };

    const isSERP = (route) => {
      const routeBaseName = getRouteBaseName(route);
      return (
        routeBaseName === RBN_COUNTRY_SEARCH
        || routeBaseName === RBN_CITY_SEARCH
        || routeBaseName === RBN_FEDERAL_STATE_SEARCH
        || routeBaseName === RBN_REGION_SEARCH
        || routeBaseName === RBN_SEARCH
      );
    };

    if (isCPDP(to)) {
      if (isCPDP(from)) {
        if (to.params.identifier !== from.params.identifier) {
          return delayedScrollValues({ top: 0, left: 0 });
        }
        return;
      }
      // CPDP handles hash scrolling by itself
      return delayedScrollValues({ top: savedPosition?.top || 0, left: savedPosition?.left || 0 }, 100);
    }

    if (isSERP(from) && isSERP(to)) {
      if (from.name !== to.name) {
        return delayedScrollValues({ top: savedPosition?.top || 0, left: savedPosition?.left || 0 }, 0);
      }
      return;
    }

    if (to.path === from.path && to.hash) {
      return delayedScrollValues({ el: to.hash, left: 0 });
    }

    if (to.hash) {
      return delayedScrollValues({ el: to.hash, left: 0 }, 100);
    }

    return delayedScrollValues({ top: savedPosition?.top || 0, left: savedPosition?.left || 0 }, 0);
  },
  // https://github.com/vuejs/vue-router/issues/1259
  parseQuery: qs.parse,
  stringifyQuery: qs.stringify,
};
