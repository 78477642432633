import routes from '@/locales/routeTranslations.json';

export const translateUrl = (url, validLocales, lang = 'en') => {
  const [_, ...rest] = url.replace(/^\/+/, '').replace(/\/+$/, '').split('/');

  // Route has correct language prefix
  if (validLocales.includes(lang)) {
    let found = false;

    for (const [_, routeTranslations] of Object.entries(routes)) {
      for (const [englishRouteName, translatedRouteName] of Object.entries(routeTranslations)) {
        // Route translation found
        for (let i = 0; i < rest.length; i++) {
          const restEqualsTranslated = rest[i] === encodeURIComponent(translatedRouteName);
          const restEqualsEnglishKey = rest[i] === englishRouteName;
          if (restEqualsTranslated || restEqualsEnglishKey) {
            // Assign new route part from translated value
            if (rest[i] !== routes[lang][englishRouteName]) {
              rest[i] = encodeURIComponent(routes[lang][englishRouteName]);
              found = true;
            }
          }
        }
      }
    }
    if (found) {
      return `/${lang}/${rest.join('/')}`;
    } else {
      return url;
    }
  } else {
    return false;
  }
};

// Map civ2 languages to dayjs languages
const DAYJS_LANGUAGE_MAPPING = {
  no: 'nb',
};

export const getDayjsLanguageCode = (sourceLang) => {
  if (sourceLang in DAYJS_LANGUAGE_MAPPING) {
    return DAYJS_LANGUAGE_MAPPING[sourceLang];
  }
  return sourceLang;
};

export const getPluralSuffix = (message, num, lang) => {
  const pluralFormulas = {
    default: n => (n === 1 ? '' : '_plural'),
    bs: n =>
      (n % 10 === 1 && n % 100 !== 11
        ? '_0'
        : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
          ? '_1'
          : '_2'),
    cs: n => (n === 1 ? '_0' : n >= 2 && n <= 4 ? '_1' : '_2'),
    hr: n =>
      (n % 10 === 1 && n % 100 !== 11
        ? '_0'
        : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
          ? '_1'
          : '_2'),
    lt: n =>
      (n % 10 === 1 && (n % 100 < 11 || n % 100 > 19)
        ? '_0'
        : n % 10 >= 2 && n % 10 <= 9 && (n % 100 < 11 || n % 100 > 19)
          ? '_1'
          : '_2'),
    lv: n => (n % 10 === 0 || (n % 100 >= 11 && n % 100 <= 19) ? '_0' : n % 10 === 1 && n % 100 !== 11 ? '_1' : '_2'),
    pl: n => (n === 1 ? '_0' : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? '_1' : '_2'),
    ro: n => (n === 1 ? '_0' : n === 0 || (n % 100 > 0 && n % 100 < 20) ? '_1' : '_2'),
    ru: n =>
      (n % 10 === 1 && n % 100 !== 11
        ? '_0'
        : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
          ? '_1'
          : '_2'),
    sk: n => (n === 1 ? '_0' : n >= 2 && n <= 4 ? '_1' : '_2'),
    sl: n => (n % 100 === 1 ? '_0' : n % 100 === 2 ? '_1' : n % 100 === 3 || n % 100 === 4 ? '_2' : '_3'),
    sr: n =>
      (n % 10 === 1 && n % 100 !== 11
        ? '_0'
        : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
          ? '_1'
          : '_2'),
  };

  const pluralFormula = pluralFormulas[lang] || pluralFormulas.default;
  const suffix = pluralFormula(Math.abs(num));
  return `${message}${suffix}`;
};
