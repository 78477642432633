import type { Pinia } from 'pinia';

export default defineNuxtPlugin({
  name: 'auth',
  parallel: true,
  setup(nuxtApp) {
    const userStore = useUserStore(nuxtApp.$pinia as Pinia);
    userStore.init(nuxtApp.$i18n.locale.value);
  },
});
