const getData = [
  'account',
  'account-change-mail',
  'account-change-password',
  'account-confirm-email-change',
  'account-confirm-password',
  'account-confirmEmail',
  'account-favorites',
  'account-login',
  'account-profile',
  'account-register',
  'account-reset-password',
  'account-reviews',
  'account-uploads',
  'campsite-identifier-inquire',
  'campsite-identifier-inquire-confirm',
  'campsite-identifier-media',
  'campsite-identifier-rate',
  'campsite-identifier-rate-confirm',
  'campsite-identifier-rating-widget',
  'campsite-identifier-reviews-id',
  'campsite-identifier-upload',
  'campsite-identifier-upload-images',
  'campsite-identifier-upload-videos',
  'sea-all',
  'search',
  'searchMap',
];

export default getData;
