export default function useFetchError() {
  /********************
   * COMPOSITIONS      *
   ********************/
  const backendStore = useBackendStore();
  const { fetchStatus, fetchFailed } = storeToRefs(backendStore);

  /********************
   * FUNCTIONS         *
   ********************/
  function fetchError(err: any, message = null) {
    const statusCode = err?.response?.statusCode || err?.response?.status || 500;
    if (import.meta.server) {
      message = message || err.message;
      backendStore.setFetchStatus({ statusCode, message: message || '' });
      throw createError({ statusCode, statusMessage: message || '' });
    }
    message = message || err.message;
    backendStore.setFetchStatus({ statusCode, message: message || '' });
  }

  return {
    fetchError,
    fetchFailed,
    fetchStatus,
  };
}
