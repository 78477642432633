
// @ts-nocheck


export const localeCodes =  [
  "de",
  "en",
  "nl",
  "fr",
  "it",
  "es",
  "bg",
  "bs",
  "cs",
  "da",
  "el",
  "et",
  "fi",
  "hr",
  "hu",
  "lt",
  "lv",
  "no",
  "pl",
  "pt",
  "ro",
  "ru",
  "sk",
  "sl",
  "sr",
  "sv",
  "tr"
]

export const localeLoaders = {
  "de": [{ key: "../../../../app/locales/dist/dummy/de.json", load: () => import("../../../../app/locales/dist/dummy/de.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_de_json" */), cache: true }],
  "en": [{ key: "../../../../app/locales/dist/dummy/en.json", load: () => import("../../../../app/locales/dist/dummy/en.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_en_json" */), cache: true }],
  "nl": [{ key: "../../../../app/locales/dist/dummy/nl.json", load: () => import("../../../../app/locales/dist/dummy/nl.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_nl_json" */), cache: true }],
  "fr": [{ key: "../../../../app/locales/dist/dummy/fr.json", load: () => import("../../../../app/locales/dist/dummy/fr.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_fr_json" */), cache: true }],
  "it": [{ key: "../../../../app/locales/dist/dummy/it.json", load: () => import("../../../../app/locales/dist/dummy/it.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_it_json" */), cache: true }],
  "es": [{ key: "../../../../app/locales/dist/dummy/es.json", load: () => import("../../../../app/locales/dist/dummy/es.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_es_json" */), cache: true }],
  "bg": [{ key: "../../../../app/locales/dist/dummy/bg.json", load: () => import("../../../../app/locales/dist/dummy/bg.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_bg_json" */), cache: true }],
  "bs": [{ key: "../../../../app/locales/dist/dummy/bs.json", load: () => import("../../../../app/locales/dist/dummy/bs.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_bs_json" */), cache: true }],
  "cs": [{ key: "../../../../app/locales/dist/dummy/cs.json", load: () => import("../../../../app/locales/dist/dummy/cs.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_cs_json" */), cache: true }],
  "da": [{ key: "../../../../app/locales/dist/dummy/da.json", load: () => import("../../../../app/locales/dist/dummy/da.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_da_json" */), cache: true }],
  "el": [{ key: "../../../../app/locales/dist/dummy/el.json", load: () => import("../../../../app/locales/dist/dummy/el.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_el_json" */), cache: true }],
  "et": [{ key: "../../../../app/locales/dist/dummy/et.json", load: () => import("../../../../app/locales/dist/dummy/et.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_et_json" */), cache: true }],
  "fi": [{ key: "../../../../app/locales/dist/dummy/fi.json", load: () => import("../../../../app/locales/dist/dummy/fi.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_fi_json" */), cache: true }],
  "hr": [{ key: "../../../../app/locales/dist/dummy/hr.json", load: () => import("../../../../app/locales/dist/dummy/hr.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_hr_json" */), cache: true }],
  "hu": [{ key: "../../../../app/locales/dist/dummy/hu.json", load: () => import("../../../../app/locales/dist/dummy/hu.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_hu_json" */), cache: true }],
  "lt": [{ key: "../../../../app/locales/dist/dummy/lt.json", load: () => import("../../../../app/locales/dist/dummy/lt.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_lt_json" */), cache: true }],
  "lv": [{ key: "../../../../app/locales/dist/dummy/lv.json", load: () => import("../../../../app/locales/dist/dummy/lv.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_lv_json" */), cache: true }],
  "no": [{ key: "../../../../app/locales/dist/dummy/no.json", load: () => import("../../../../app/locales/dist/dummy/no.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_no_json" */), cache: true }],
  "pl": [{ key: "../../../../app/locales/dist/dummy/pl.json", load: () => import("../../../../app/locales/dist/dummy/pl.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_pl_json" */), cache: true }],
  "pt": [{ key: "../../../../app/locales/dist/dummy/pt.json", load: () => import("../../../../app/locales/dist/dummy/pt.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_pt_json" */), cache: true }],
  "ro": [{ key: "../../../../app/locales/dist/dummy/ro.json", load: () => import("../../../../app/locales/dist/dummy/ro.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_ro_json" */), cache: true }],
  "ru": [{ key: "../../../../app/locales/dist/dummy/ru.json", load: () => import("../../../../app/locales/dist/dummy/ru.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_ru_json" */), cache: true }],
  "sk": [{ key: "../../../../app/locales/dist/dummy/sk.json", load: () => import("../../../../app/locales/dist/dummy/sk.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_sk_json" */), cache: true }],
  "sl": [{ key: "../../../../app/locales/dist/dummy/sl.json", load: () => import("../../../../app/locales/dist/dummy/sl.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_sl_json" */), cache: true }],
  "sr": [{ key: "../../../../app/locales/dist/dummy/sr.json", load: () => import("../../../../app/locales/dist/dummy/sr.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_sr_json" */), cache: true }],
  "sv": [{ key: "../../../../app/locales/dist/dummy/sv.json", load: () => import("../../../../app/locales/dist/dummy/sv.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_sv_json" */), cache: true }],
  "tr": [{ key: "../../../../app/locales/dist/dummy/tr.json", load: () => import("../../../../app/locales/dist/dummy/tr.json" /* webpackChunkName: "locale__builds_campinginfo_civ2_nuxt_3_app_locales_dist_dummy_tr_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "de",
      "name": "Deutsch",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/de.json"
      ]
    },
    {
      "code": "en",
      "name": "English",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/en.json"
      ]
    },
    {
      "code": "nl",
      "name": "Nederlands",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/nl.json"
      ]
    },
    {
      "code": "fr",
      "name": "français",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/fr.json"
      ]
    },
    {
      "code": "it",
      "name": "italiano",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/it.json"
      ]
    },
    {
      "code": "es",
      "name": "Español",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/es.json"
      ]
    },
    {
      "code": "bg",
      "name": "български",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/bg.json"
      ]
    },
    {
      "code": "bs",
      "name": "босански",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/bs.json"
      ]
    },
    {
      "code": "cs",
      "name": "čeština",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/cs.json"
      ]
    },
    {
      "code": "da",
      "name": "dansk",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/da.json"
      ]
    },
    {
      "code": "el",
      "name": "Ελληνικά",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/el.json"
      ]
    },
    {
      "code": "et",
      "name": "eesti",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/et.json"
      ]
    },
    {
      "code": "fi",
      "name": "suomi",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/fi.json"
      ]
    },
    {
      "code": "hr",
      "name": "hrvatski",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/hr.json"
      ]
    },
    {
      "code": "hu",
      "name": "magyar",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/hu.json"
      ]
    },
    {
      "code": "lt",
      "name": "lietuvių",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/lt.json"
      ]
    },
    {
      "code": "lv",
      "name": "latviešu",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/lv.json"
      ]
    },
    {
      "code": "no",
      "name": "norsk bokmål",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/no.json"
      ]
    },
    {
      "code": "pl",
      "name": "polski",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/pl.json"
      ]
    },
    {
      "code": "pt",
      "name": "português",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/pt.json"
      ]
    },
    {
      "code": "ro",
      "name": "română",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/ro.json"
      ]
    },
    {
      "code": "ru",
      "name": "русский",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/ru.json"
      ]
    },
    {
      "code": "sk",
      "name": "slovenčina",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/sk.json"
      ]
    },
    {
      "code": "sl",
      "name": "slovenščina",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/sl.json"
      ]
    },
    {
      "code": "sr",
      "name": "српски",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/sr.json"
      ]
    },
    {
      "code": "sv",
      "name": "svenska",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/sv.json"
      ]
    },
    {
      "code": "tr",
      "name": "Türkçe",
      "files": [
        "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/tr.json"
      ]
    }
  ],
  "defaultLocale": "de",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "./locales/dist/dummy/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://www.camping.info",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "page-is-gone": {
      "de": "/page-is-gone",
      "en": "/page-is-gone",
      "es": "/page-is-gone",
      "bg": "/page-is-gone",
      "bs": "/page-is-gone",
      "cs": "/page-is-gone",
      "da": "/page-is-gone",
      "el": "/page-is-gone",
      "et": "/page-is-gone",
      "fi": "/page-is-gone",
      "fr": "/page-is-gone",
      "hr": "/page-is-gone",
      "hu": "/page-is-gone",
      "it": "/page-is-gone",
      "lt": "/page-is-gone",
      "lv": "/page-is-gone",
      "no": "/page-is-gone",
      "nl": "/page-is-gone",
      "pl": "/page-is-gone",
      "pt": "/page-is-gone",
      "ro": "/page-is-gone",
      "ru": "/page-is-gone",
      "sk": "/page-is-gone",
      "sl": "/page-is-gone",
      "sr": "/page-is-gone",
      "sv": "/page-is-gone",
      "tr": "/page-is-gone"
    },
    "topic/index": {
      "de": "/thema",
      "en": "/topic",
      "es": "/tema",
      "bg": "/тема",
      "bs": "/temu",
      "cs": "/téma",
      "da": "/emne",
      "el": "/θέμα",
      "et": "/teema",
      "fi": "/aihe",
      "fr": "/sujet",
      "hr": "/tema",
      "hu": "/téma",
      "it": "/argomento",
      "lt": "/tema",
      "lv": "/temats",
      "no": "/emne",
      "nl": "/onderwerp",
      "pl": "/temat",
      "pt": "/tema",
      "ro": "/subiect",
      "ru": "/тема",
      "sk": "/téma",
      "sl": "/tema",
      "sr": "/тема",
      "sv": "/ämne",
      "tr": "/konu"
    },
    "topic/[...all]": {
      "de": "/thema/[...all]",
      "en": "/topic/[...all]",
      "es": "/tema/[...all]",
      "bg": "/тема/[...all]",
      "bs": "/temu/[...all]",
      "cs": "/téma/[...all]",
      "da": "/emne/[...all]",
      "el": "/θέμα/[...all]",
      "et": "/teema/[...all]",
      "fi": "/aihe/[...all]",
      "fr": "/sujet/[...all]",
      "hr": "/tema/[...all]",
      "hu": "/téma/[...all]",
      "it": "/argomento/[...all]",
      "lt": "/tema/[...all]",
      "lv": "/temats/[...all]",
      "no": "/emne/[...all]",
      "nl": "/onderwerp/[...all]",
      "pl": "/temat/[...all]",
      "pt": "/tema/[...all]",
      "ro": "/subiect/[...all]",
      "ru": "/тема/[...all]",
      "sk": "/téma/[...all]",
      "sl": "/tema/[...all]",
      "sr": "/тема/[...all]",
      "sv": "/ämne/[...all]",
      "tr": "/konu/[...all]"
    },
    "searchMap/index": {
      "de": "/auf-karte-suchen",
      "en": "/search-on-map",
      "es": "/buscar-en-el-mapa",
      "bg": "/търсене-карта",
      "bs": "/mapa-pretrage",
      "cs": "/vyhledavaci-mapa",
      "da": "/søge-kort",
      "el": "/αναζητηση-χαρτη",
      "et": "/otsingu-kaart",
      "fi": "/haku-kartta",
      "fr": "/recherche-carte",
      "hr": "/mapa-pretrage",
      "hu": "/kereses-terkepen",
      "it": "/cerca-mappa",
      "lt": "/ieskoti-zemelapyje",
      "lv": "/mekle-karti",
      "no": "/soek-kart",
      "nl": "/zoek-op-kaart",
      "pl": "/mapa-wyszukiwania",
      "pt": "/mapa-de-pesquisa",
      "ro": "/cautare-harta",
      "ru": "/поиск-на-карте",
      "sk": "/hladat-na-mape",
      "sl": "/iskanje-zemljevid",
      "sr": "/pretraga-mape",
      "sv": "/sok-karta",
      "tr": "/haritada-ara"
    },
    "search/index": {
      "de": "/suche",
      "en": "/search",
      "es": "/buscar",
      "bg": "/търсене",
      "bs": "/trazi",
      "cs": "/hledat",
      "da": "/søg",
      "el": "/αναζητηση",
      "et": "/otsing",
      "fi": "/haku",
      "fr": "/recherche",
      "hr": "/trazi",
      "hu": "/kereses",
      "it": "/cerca",
      "lt": "/paieska",
      "lv": "/meklet",
      "no": "/soek",
      "nl": "/zoeken",
      "pl": "/szukaj",
      "pt": "/procurar",
      "ro": "/cautare",
      "ru": "/поиск",
      "sk": "/vyhladavanie",
      "sl": "/isci",
      "sr": "/pretraga",
      "sv": "/sok",
      "tr": "/ara"
    },
    "sea/[...all]": {
      "de": "/sea/[...all]",
      "en": "/sea/[...all]",
      "es": "/sea/[...all]",
      "bg": "/sea/[...all]",
      "bs": "/sea/[...all]",
      "cs": "/sea/[...all]",
      "da": "/sea/[...all]",
      "el": "/sea/[...all]",
      "et": "/sea/[...all]",
      "fi": "/sea/[...all]",
      "fr": "/sea/[...all]",
      "hr": "/sea/[...all]",
      "hu": "/sea/[...all]",
      "it": "/sea/[...all]",
      "lt": "/sea/[...all]",
      "lv": "/sea/[...all]",
      "no": "/sea/[...all]",
      "nl": "/sea/[...all]",
      "pl": "/sea/[...all]",
      "pt": "/sea/[...all]",
      "ro": "/sea/[...all]",
      "ru": "/sea/[...all]",
      "sk": "/sea/[...all]",
      "sl": "/sea/[...all]",
      "sr": "/sea/[...all]",
      "sv": "/sea/[...all]",
      "tr": "/sea/[...all]"
    },
    "popularRegion/index": {
      "de": "/beliebte-region",
      "en": "/popular-region",
      "es": "/región-popular",
      "bg": "/популярна-област",
      "bs": "/popularan-region",
      "cs": "/oblibene-regiony",
      "da": "/populær-region",
      "el": "/δημοφιλης-περιοχη",
      "et": "/populaarne-piirkond",
      "fi": "/suosittu-alue",
      "fr": "/region-populaire",
      "hr": "/popularna-regija",
      "hu": "/nepszeru-regio",
      "it": "/regione-popolare",
      "lt": "/populiarus-regionas",
      "lv": "/populari-regioni",
      "no": "/populaer-region",
      "nl": "/populaire-landen",
      "pl": "/popularny-region",
      "pt": "/regiao-popular",
      "ro": "/regiune-populara",
      "ru": "/популярныи-регион",
      "sk": "/popularna-oblast",
      "sl": "/priljubljena-regija",
      "sr": "/popularan-region",
      "sv": "/popular-region",
      "tr": "/populer-bolge"
    },
    "popularRegion/[regionSlug]/index": {
      "de": "/beliebte-region/[regionSlug]",
      "en": "/popular-region/[regionSlug]",
      "es": "/región-popular/[regionSlug]",
      "bg": "/популярна-област/[regionSlug]",
      "bs": "/popularan-region/[regionSlug]",
      "cs": "/oblibene-regiony/[regionSlug]",
      "da": "/populær-region/[regionSlug]",
      "el": "/δημοφιλης-περιοχη/[regionSlug]",
      "et": "/populaarne-piirkond/[regionSlug]",
      "fi": "/suosittu-alue/[regionSlug]",
      "fr": "/region-populaire/[regionSlug]",
      "hr": "/popularna-regija/[regionSlug]",
      "hu": "/nepszeru-regio/[regionSlug]",
      "it": "/regione-popolare/[regionSlug]",
      "lt": "/populiarus-regionas/[regionSlug]",
      "lv": "/populari-regioni/[regionSlug]",
      "no": "/populaer-region/[regionSlug]",
      "nl": "/populaire-landen/[regionSlug]",
      "pl": "/popularny-region/[regionSlug]",
      "pt": "/regiao-popular/[regionSlug]",
      "ro": "/regiune-populara/[regionSlug]",
      "ru": "/популярныи-регион/[regionSlug]",
      "sk": "/popularna-oblast/[regionSlug]",
      "sl": "/priljubljena-regija/[regionSlug]",
      "sr": "/popularan-region/[regionSlug]",
      "sv": "/popular-region/[regionSlug]",
      "tr": "/populer-bolge/[regionSlug]"
    },
    "info/index": {
      "de": "/info",
      "en": "/info",
      "es": "/información",
      "bg": "/инфо",
      "bs": "/info",
      "cs": "/informace",
      "da": "/info",
      "el": "/πληροφορίες",
      "et": "/info",
      "fi": "/tiedot",
      "fr": "/info",
      "hr": "/informacije",
      "hu": "/info",
      "it": "/informazioni",
      "lt": "/informacija",
      "lv": "/info",
      "no": "/info",
      "nl": "/info",
      "pl": "/informacje",
      "pt": "/informação",
      "ro": "/info",
      "ru": "/информация",
      "sk": "/info",
      "sl": "/info",
      "sr": "/инфо",
      "sv": "/info",
      "tr": "/bilgi"
    },
    "info/[...all]": {
      "de": "/info/[...all]",
      "en": "/info/[...all]",
      "es": "/información/[...all]",
      "bg": "/инфо/[...all]",
      "bs": "/info/[...all]",
      "cs": "/informace/[...all]",
      "da": "/info/[...all]",
      "el": "/πληροφορίες/[...all]",
      "et": "/info/[...all]",
      "fi": "/tiedot/[...all]",
      "fr": "/info/[...all]",
      "hr": "/informacije/[...all]",
      "hu": "/info/[...all]",
      "it": "/informazioni/[...all]",
      "lt": "/informacija/[...all]",
      "lv": "/info/[...all]",
      "no": "/info/[...all]",
      "nl": "/info/[...all]",
      "pl": "/informacje/[...all]",
      "pt": "/informação/[...all]",
      "ro": "/info/[...all]",
      "ru": "/информация/[...all]",
      "sk": "/info/[...all]",
      "sl": "/info/[...all]",
      "sr": "/инфо/[...all]",
      "sv": "/info/[...all]",
      "tr": "/bilgi/[...all]"
    },
    "country/index": {
      "de": "/land",
      "en": "/country",
      "es": "/país",
      "bg": "/държава",
      "bs": "/drzava",
      "cs": "/zeme",
      "da": "/land",
      "el": "/χωρα",
      "et": "/riik",
      "fi": "/maa",
      "fr": "/pays",
      "hr": "/zemlja",
      "hu": "/orszag",
      "it": "/paese",
      "lt": "/salis",
      "lv": "/valsts",
      "no": "/land",
      "nl": "/land",
      "pl": "/kraj",
      "pt": "/pais",
      "ro": "/tara",
      "ru": "/страна",
      "sk": "/krajina",
      "sl": "/drzava",
      "sr": "/zemlja",
      "sv": "/land",
      "tr": "/ulke"
    },
    "country/[countrySlug]/index": {
      "de": "/land/[countrySlug]",
      "en": "/country/[countrySlug]",
      "es": "/país/[countrySlug]",
      "bg": "/държава/[countrySlug]",
      "bs": "/drzava/[countrySlug]",
      "cs": "/zeme/[countrySlug]",
      "da": "/land/[countrySlug]",
      "el": "/χωρα/[countrySlug]",
      "et": "/riik/[countrySlug]",
      "fi": "/maa/[countrySlug]",
      "fr": "/pays/[countrySlug]",
      "hr": "/zemlja/[countrySlug]",
      "hu": "/orszag/[countrySlug]",
      "it": "/paese/[countrySlug]",
      "lt": "/salis/[countrySlug]",
      "lv": "/valsts/[countrySlug]",
      "no": "/land/[countrySlug]",
      "nl": "/land/[countrySlug]",
      "pl": "/kraj/[countrySlug]",
      "pt": "/pais/[countrySlug]",
      "ro": "/tara/[countrySlug]",
      "ru": "/страна/[countrySlug]",
      "sk": "/krajina/[countrySlug]",
      "sl": "/drzava/[countrySlug]",
      "sr": "/zemlja/[countrySlug]",
      "sv": "/land/[countrySlug]",
      "tr": "/ulke/[countrySlug]"
    },
    "country/[countrySlug]/[federalStateSlug]/index": {
      "de": "/land/[countrySlug]/[federalStateSlug]",
      "en": "/country/[countrySlug]/[federalStateSlug]",
      "es": "/país/[countrySlug]/[federalStateSlug]",
      "bg": "/държава/[countrySlug]/[federalStateSlug]",
      "bs": "/drzava/[countrySlug]/[federalStateSlug]",
      "cs": "/zeme/[countrySlug]/[federalStateSlug]",
      "da": "/land/[countrySlug]/[federalStateSlug]",
      "el": "/χωρα/[countrySlug]/[federalStateSlug]",
      "et": "/riik/[countrySlug]/[federalStateSlug]",
      "fi": "/maa/[countrySlug]/[federalStateSlug]",
      "fr": "/pays/[countrySlug]/[federalStateSlug]",
      "hr": "/zemlja/[countrySlug]/[federalStateSlug]",
      "hu": "/orszag/[countrySlug]/[federalStateSlug]",
      "it": "/paese/[countrySlug]/[federalStateSlug]",
      "lt": "/salis/[countrySlug]/[federalStateSlug]",
      "lv": "/valsts/[countrySlug]/[federalStateSlug]",
      "no": "/land/[countrySlug]/[federalStateSlug]",
      "nl": "/land/[countrySlug]/[federalStateSlug]",
      "pl": "/kraj/[countrySlug]/[federalStateSlug]",
      "pt": "/pais/[countrySlug]/[federalStateSlug]",
      "ro": "/tara/[countrySlug]/[federalStateSlug]",
      "ru": "/страна/[countrySlug]/[federalStateSlug]",
      "sk": "/krajina/[countrySlug]/[federalStateSlug]",
      "sl": "/drzava/[countrySlug]/[federalStateSlug]",
      "sr": "/zemlja/[countrySlug]/[federalStateSlug]",
      "sv": "/land/[countrySlug]/[federalStateSlug]",
      "tr": "/ulke/[countrySlug]/[federalStateSlug]"
    },
    "city/[citySlug]/index": {
      "de": "/stadt/[citySlug]",
      "en": "/city/[citySlug]",
      "es": "/ciudad/[citySlug]",
      "bg": "/град/[citySlug]",
      "bs": "/grad/[citySlug]",
      "cs": "/grad/[citySlug]",
      "da": "/by/[citySlug]",
      "el": "/πόλη/[citySlug]",
      "et": "/linn/[citySlug]",
      "fi": "/kaupunki/[citySlug]",
      "fr": "/ville/[citySlug]",
      "hr": "/grad/[citySlug]",
      "hu": "/város/[citySlug]",
      "it": "/città/[citySlug]",
      "lt": "/miestas/[citySlug]",
      "lv": "/pilsēta/[citySlug]",
      "no": "/by/[citySlug]",
      "nl": "/stad/[citySlug]",
      "pl": "/miasto/[citySlug]",
      "pt": "/cidade/[citySlug]",
      "ro": "/oraș/[citySlug]",
      "ru": "/город/[citySlug]",
      "sk": "/mesto/[citySlug]",
      "sl": "/mesto/[citySlug]",
      "sr": "/град/[citySlug]",
      "sv": "/stad/[citySlug]",
      "tr": "/kent/[citySlug]"
    },
    "campsite/index": {
      "de": "/campingplatz",
      "en": "/campsite",
      "es": "/camping",
      "bg": "/лагер",
      "bs": "/kamp-mjesto",
      "cs": "/kemp",
      "da": "/campinggrund",
      "el": "/θεση-καμπινγκ",
      "et": "/kamping",
      "fi": "/leirintaalue",
      "fr": "/emplacement-de-camping",
      "hr": "/mjesto-za-kampiranje",
      "hu": "/taborhely",
      "it": "/campeggio",
      "lt": "/stovyklaviete",
      "lv": "/karavanu-vieta",
      "no": "/campingplass",
      "nl": "/kampeerplek",
      "pl": "/miejsce-kempingowe",
      "pt": "/acampamento",
      "ro": "/loc-de-campare",
      "ru": "/кемпинг-лагерь",
      "sk": "/miesto-na-kempovanie",
      "sl": "/kamp",
      "sr": "/kamp-lokacija",
      "sv": "/taltplats",
      "tr": "/kamp-alani"
    },
    "campsite/[identifier]/index": {
      "de": "/campingplatz/[identifier]",
      "en": "/campsite/[identifier]",
      "es": "/camping/[identifier]",
      "bg": "/лагер/[identifier]",
      "bs": "/kamp-mjesto/[identifier]",
      "cs": "/kemp/[identifier]",
      "da": "/campinggrund/[identifier]",
      "el": "/θεση-καμπινγκ/[identifier]",
      "et": "/kamping/[identifier]",
      "fi": "/leirintaalue/[identifier]",
      "fr": "/emplacement-de-camping/[identifier]",
      "hr": "/mjesto-za-kampiranje/[identifier]",
      "hu": "/taborhely/[identifier]",
      "it": "/campeggio/[identifier]",
      "lt": "/stovyklaviete/[identifier]",
      "lv": "/karavanu-vieta/[identifier]",
      "no": "/campingplass/[identifier]",
      "nl": "/kampeerplek/[identifier]",
      "pl": "/miejsce-kempingowe/[identifier]",
      "pt": "/acampamento/[identifier]",
      "ro": "/loc-de-campare/[identifier]",
      "ru": "/кемпинг-лагерь/[identifier]",
      "sk": "/miesto-na-kempovanie/[identifier]",
      "sl": "/kamp/[identifier]",
      "sr": "/kamp-lokacija/[identifier]",
      "sv": "/taltplats/[identifier]",
      "tr": "/kamp-alani/[identifier]"
    },
    "campsite/[identifier]/upload/index": {
      "de": "/campingplatz/[identifier]/hochladen",
      "en": "/campsite/[identifier]/upload",
      "es": "/camping/[identifier]/subir",
      "bg": "/лагер/[identifier]/качване",
      "bs": "/kamp-mjesto/[identifier]/upload",
      "cs": "/kemp/[identifier]/nahrát",
      "da": "/campinggrund/[identifier]/uploade",
      "el": "/θεση-καμπινγκ/[identifier]/upload",
      "et": "/kamping/[identifier]/laadi-üles",
      "fi": "/leirintaalue/[identifier]/upload",
      "fr": "/emplacement-de-camping/[identifier]/télécharger",
      "hr": "/mjesto-za-kampiranje/[identifier]/uploadati",
      "hu": "/taborhely/[identifier]/upload",
      "it": "/campeggio/[identifier]/upload",
      "lt": "/stovyklaviete/[identifier]/įkelti",
      "lv": "/karavanu-vieta/[identifier]/augšupielādēt",
      "no": "/campingplass/[identifier]/laste-opp",
      "nl": "/kampeerplek/[identifier]/uploaden",
      "pl": "/miejsce-kempingowe/[identifier]/przekazać-plik",
      "pt": "/acampamento/[identifier]/inserir",
      "ro": "/loc-de-campare/[identifier]/încărcare",
      "ru": "/кемпинг-лагерь/[identifier]/загрузка",
      "sk": "/miesto-na-kempovanie/[identifier]/upload",
      "sl": "/kamp/[identifier]/upload",
      "sr": "/kamp-lokacija/[identifier]/upload",
      "sv": "/taltplats/[identifier]/ladda-upp",
      "tr": "/kamp-alani/[identifier]/yüklemek"
    },
    "campsite/[identifier]/upload/videos/index": {
      "de": "/campingplatz/[identifier]/hochladen/videos",
      "en": "/campsite/[identifier]/upload/videos",
      "es": "/camping/[identifier]/subir/vídeos",
      "bg": "/лагер/[identifier]/качване/видеоклипове",
      "bs": "/kamp-mjesto/[identifier]/upload/video",
      "cs": "/kemp/[identifier]/nahrát/videa",
      "da": "/campinggrund/[identifier]/uploade/videoer",
      "el": "/θεση-καμπινγκ/[identifier]/upload/βίντεο",
      "et": "/kamping/[identifier]/laadi-üles/videod",
      "fi": "/leirintaalue/[identifier]/upload/videot",
      "fr": "/emplacement-de-camping/[identifier]/télécharger/vidéos",
      "hr": "/mjesto-za-kampiranje/[identifier]/uploadati/video",
      "hu": "/taborhely/[identifier]/upload/videók",
      "it": "/campeggio/[identifier]/upload/video",
      "lt": "/stovyklaviete/[identifier]/įkelti/vaizdo-įrašai",
      "lv": "/karavanu-vieta/[identifier]/augšupielādēt/video",
      "no": "/campingplass/[identifier]/laste-opp/videoer",
      "nl": "/kampeerplek/[identifier]/uploaden/videos",
      "pl": "/miejsce-kempingowe/[identifier]/przekazać-plik/filmy",
      "pt": "/acampamento/[identifier]/inserir/vídeos",
      "ro": "/loc-de-campare/[identifier]/încărcare/videoclipuri",
      "ru": "/кемпинг-лагерь/[identifier]/загрузка/видео",
      "sk": "/miesto-na-kempovanie/[identifier]/upload/videá",
      "sl": "/kamp/[identifier]/upload/videi",
      "sr": "/kamp-lokacija/[identifier]/upload/video",
      "sv": "/taltplats/[identifier]/ladda-upp/videor",
      "tr": "/kamp-alani/[identifier]/yüklemek/videolar"
    },
    "campsite/[identifier]/upload/images/index": {
      "de": "/campingplatz/[identifier]/hochladen/bilder",
      "en": "/campsite/[identifier]/upload/images",
      "es": "/camping/[identifier]/subir/imágenes",
      "bg": "/лагер/[identifier]/качване/снимки",
      "bs": "/kamp-mjesto/[identifier]/upload/slike",
      "cs": "/kemp/[identifier]/nahrát/obrazy",
      "da": "/campinggrund/[identifier]/uploade/billeder",
      "el": "/θεση-καμπινγκ/[identifier]/upload/εικόνες",
      "et": "/kamping/[identifier]/laadi-üles/pildid",
      "fi": "/leirintaalue/[identifier]/upload/kuvat",
      "fr": "/emplacement-de-camping/[identifier]/télécharger/images",
      "hr": "/mjesto-za-kampiranje/[identifier]/uploadati/slike",
      "hu": "/taborhely/[identifier]/upload/képek",
      "it": "/campeggio/[identifier]/upload/immagini",
      "lt": "/stovyklaviete/[identifier]/įkelti/paveikslėliai",
      "lv": "/karavanu-vieta/[identifier]/augšupielādēt/bildes",
      "no": "/campingplass/[identifier]/laste-opp/bilder",
      "nl": "/kampeerplek/[identifier]/uploaden/afbeeldingen",
      "pl": "/miejsce-kempingowe/[identifier]/przekazać-plik/zdjęcia",
      "pt": "/acampamento/[identifier]/inserir/fotos",
      "ro": "/loc-de-campare/[identifier]/încărcare/poze",
      "ru": "/кемпинг-лагерь/[identifier]/загрузка/фотографии",
      "sk": "/miesto-na-kempovanie/[identifier]/upload/fotografie",
      "sl": "/kamp/[identifier]/upload/slike",
      "sr": "/kamp-lokacija/[identifier]/upload/slike",
      "sv": "/taltplats/[identifier]/ladda-upp/bilder",
      "tr": "/kamp-alani/[identifier]/yüklemek/resimler"
    },
    "campsite/[identifier]/reviews/index": {
      "de": "/campingplatz/[identifier]/bewertungen",
      "en": "/campsite/[identifier]/reviews",
      "es": "/camping/[identifier]/valoraciones",
      "bg": "/лагер/[identifier]/мнения",
      "bs": "/kamp-mjesto/[identifier]/recenzije",
      "cs": "/kemp/[identifier]/recenze",
      "da": "/campinggrund/[identifier]/anmeldelser",
      "el": "/θεση-καμπινγκ/[identifier]/σχόλια",
      "et": "/kamping/[identifier]/ülevaated",
      "fi": "/leirintaalue/[identifier]/arvostelut",
      "fr": "/emplacement-de-camping/[identifier]/critiques",
      "hr": "/mjesto-za-kampiranje/[identifier]/recenzije",
      "hu": "/taborhely/[identifier]/vélemények",
      "it": "/campeggio/[identifier]/recensioni",
      "lt": "/stovyklaviete/[identifier]/atsiliepimai",
      "lv": "/karavanu-vieta/[identifier]/atsauksmes",
      "no": "/campingplass/[identifier]/anmeldelser",
      "nl": "/kampeerplek/[identifier]/beoordelingen",
      "pl": "/miejsce-kempingowe/[identifier]/recenzje",
      "pt": "/acampamento/[identifier]/rever",
      "ro": "/loc-de-campare/[identifier]/comentarii",
      "ru": "/кемпинг-лагерь/[identifier]/отзывы",
      "sk": "/miesto-na-kempovanie/[identifier]/recenzia",
      "sl": "/kamp/[identifier]/pregledi",
      "sr": "/kamp-lokacija/[identifier]/mišljenja",
      "sv": "/taltplats/[identifier]/recensioner",
      "tr": "/kamp-alani/[identifier]/yorumlar"
    },
    "campsite/[identifier]/reviews/[id]/index": {
      "de": "/campingplatz/[identifier]/bewertungen/[id]",
      "en": "/campsite/[identifier]/reviews/[id]",
      "es": "/camping/[identifier]/valoraciones/[id]",
      "bg": "/лагер/[identifier]/мнения/[id]",
      "bs": "/kamp-mjesto/[identifier]/recenzije/[id]",
      "cs": "/kemp/[identifier]/recenze/[id]",
      "da": "/campinggrund/[identifier]/anmeldelser/[id]",
      "el": "/θεση-καμπινγκ/[identifier]/σχόλια/[id]",
      "et": "/kamping/[identifier]/ülevaated/[id]",
      "fi": "/leirintaalue/[identifier]/arvostelut/[id]",
      "fr": "/emplacement-de-camping/[identifier]/critiques/[id]",
      "hr": "/mjesto-za-kampiranje/[identifier]/recenzije/[id]",
      "hu": "/taborhely/[identifier]/vélemények/[id]",
      "it": "/campeggio/[identifier]/recensioni/[id]",
      "lt": "/stovyklaviete/[identifier]/atsiliepimai/[id]",
      "lv": "/karavanu-vieta/[identifier]/atsauksmes/[id]",
      "no": "/campingplass/[identifier]/anmeldelser/[id]",
      "nl": "/kampeerplek/[identifier]/beoordelingen/[id]",
      "pl": "/miejsce-kempingowe/[identifier]/recenzje/[id]",
      "pt": "/acampamento/[identifier]/rever/[id]",
      "ro": "/loc-de-campare/[identifier]/comentarii/[id]",
      "ru": "/кемпинг-лагерь/[identifier]/отзывы/[id]",
      "sk": "/miesto-na-kempovanie/[identifier]/recenzia/[id]",
      "sl": "/kamp/[identifier]/pregledi/[id]",
      "sr": "/kamp-lokacija/[identifier]/mišljenja/[id]",
      "sv": "/taltplats/[identifier]/recensioner/[id]",
      "tr": "/kamp-alani/[identifier]/yorumlar/[id]"
    },
    "campsite/[identifier]/rating-widget/index": {
      "de": "/campingplatz/[identifier]/rating-widget",
      "en": "/campsite/[identifier]/rating-widget",
      "es": "/camping/[identifier]/rating-widget",
      "bg": "/лагер/[identifier]/rating-widget",
      "bs": "/kamp-mjesto/[identifier]/rating-widget",
      "cs": "/kemp/[identifier]/rating-widget",
      "da": "/campinggrund/[identifier]/rating-widget",
      "el": "/θεση-καμπινγκ/[identifier]/rating-widget",
      "et": "/kamping/[identifier]/rating-widget",
      "fi": "/leirintaalue/[identifier]/rating-widget",
      "fr": "/emplacement-de-camping/[identifier]/rating-widget",
      "hr": "/mjesto-za-kampiranje/[identifier]/rating-widget",
      "hu": "/taborhely/[identifier]/rating-widget",
      "it": "/campeggio/[identifier]/rating-widget",
      "lt": "/stovyklaviete/[identifier]/rating-widget",
      "lv": "/karavanu-vieta/[identifier]/rating-widget",
      "no": "/campingplass/[identifier]/rating-widget",
      "nl": "/kampeerplek/[identifier]/rating-widget",
      "pl": "/miejsce-kempingowe/[identifier]/rating-widget",
      "pt": "/acampamento/[identifier]/rating-widget",
      "ro": "/loc-de-campare/[identifier]/rating-widget",
      "ru": "/кемпинг-лагерь/[identifier]/rating-widget",
      "sk": "/miesto-na-kempovanie/[identifier]/rating-widget",
      "sl": "/kamp/[identifier]/rating-widget",
      "sr": "/kamp-lokacija/[identifier]/rating-widget",
      "sv": "/taltplats/[identifier]/rating-widget",
      "tr": "/kamp-alani/[identifier]/rating-widget"
    },
    "campsite/[identifier]/rate/index": {
      "de": "/campingplatz/[identifier]/bewerten",
      "en": "/campsite/[identifier]/rate",
      "es": "/camping/[identifier]/valorar",
      "bg": "/лагер/[identifier]/оцени",
      "bs": "/kamp-mjesto/[identifier]/procijeniti",
      "cs": "/kemp/[identifier]/posoudit",
      "da": "/campinggrund/[identifier]/vurdere",
      "el": "/θεση-καμπινγκ/[identifier]/αξιολόγηση",
      "et": "/kamping/[identifier]/hindama",
      "fi": "/leirintaalue/[identifier]/arvioida",
      "fr": "/emplacement-de-camping/[identifier]/évaluer",
      "hr": "/mjesto-za-kampiranje/[identifier]/procijeniti",
      "hu": "/taborhely/[identifier]/értékeli",
      "it": "/campeggio/[identifier]/valutare",
      "lt": "/stovyklaviete/[identifier]/įvertinti",
      "lv": "/karavanu-vieta/[identifier]/noteikt",
      "no": "/campingplass/[identifier]/vurdere",
      "nl": "/kampeerplek/[identifier]/schatten",
      "pl": "/miejsce-kempingowe/[identifier]/oszacować",
      "pt": "/acampamento/[identifier]/avaliar",
      "ro": "/loc-de-campare/[identifier]/evalua",
      "ru": "/кемпинг-лагерь/[identifier]/оценить",
      "sk": "/miesto-na-kempovanie/[identifier]/posúdiť",
      "sl": "/kamp/[identifier]/oceniti",
      "sr": "/kamp-lokacija/[identifier]/proceniti",
      "sv": "/taltplats/[identifier]/bedöma",
      "tr": "/kamp-alani/[identifier]/belirlemek"
    },
    "campsite/[identifier]/rate/confirm/index": {
      "de": "/campingplatz/[identifier]/bewerten/bestätigen",
      "en": "/campsite/[identifier]/rate/confirm",
      "es": "/camping/[identifier]/valorar/confirmar",
      "bg": "/лагер/[identifier]/оцени/потвърждаване",
      "bs": "/kamp-mjesto/[identifier]/procijeniti/potvrditi",
      "cs": "/kemp/[identifier]/posoudit/potvrdit",
      "da": "/campinggrund/[identifier]/vurdere/bekræfte",
      "el": "/θεση-καμπινγκ/[identifier]/αξιολόγηση/επιβεβαιώνω",
      "et": "/kamping/[identifier]/hindama/kinnitage",
      "fi": "/leirintaalue/[identifier]/arvioida/vahvistaa",
      "fr": "/emplacement-de-camping/[identifier]/évaluer/confirmer",
      "hr": "/mjesto-za-kampiranje/[identifier]/procijeniti/potvrđuju",
      "hu": "/taborhely/[identifier]/értékeli/megerősít",
      "it": "/campeggio/[identifier]/valutare/confermare",
      "lt": "/stovyklaviete/[identifier]/įvertinti/patvirtinti",
      "lv": "/karavanu-vieta/[identifier]/noteikt/apstiprināt",
      "no": "/campingplass/[identifier]/vurdere/bekrefte",
      "nl": "/kampeerplek/[identifier]/schatten/bevestigen",
      "pl": "/miejsce-kempingowe/[identifier]/oszacować/potwierdzać",
      "pt": "/acampamento/[identifier]/avaliar/confirme",
      "ro": "/loc-de-campare/[identifier]/evalua/a-confirma",
      "ru": "/кемпинг-лагерь/[identifier]/оценить/подтверждения",
      "sk": "/miesto-na-kempovanie/[identifier]/posúdiť/potvrdit",
      "sl": "/kamp/[identifier]/oceniti/potrdite",
      "sr": "/kamp-lokacija/[identifier]/proceniti/Потврди",
      "sv": "/taltplats/[identifier]/bedöma/bekräfta",
      "tr": "/kamp-alani/[identifier]/belirlemek/onaylamak"
    },
    "campsite/[identifier]/media/index": {
      "de": "/campingplatz/[identifier]/media",
      "en": "/campsite/[identifier]/media",
      "es": "/camping/[identifier]/media",
      "bg": "/лагер/[identifier]/media",
      "bs": "/kamp-mjesto/[identifier]/media",
      "cs": "/kemp/[identifier]/media",
      "da": "/campinggrund/[identifier]/media",
      "el": "/θεση-καμπινγκ/[identifier]/media",
      "et": "/kamping/[identifier]/media",
      "fi": "/leirintaalue/[identifier]/media",
      "fr": "/emplacement-de-camping/[identifier]/media",
      "hr": "/mjesto-za-kampiranje/[identifier]/media",
      "hu": "/taborhely/[identifier]/media",
      "it": "/campeggio/[identifier]/media",
      "lt": "/stovyklaviete/[identifier]/media",
      "lv": "/karavanu-vieta/[identifier]/media",
      "no": "/campingplass/[identifier]/media",
      "nl": "/kampeerplek/[identifier]/media",
      "pl": "/miejsce-kempingowe/[identifier]/media",
      "pt": "/acampamento/[identifier]/media",
      "ro": "/loc-de-campare/[identifier]/media",
      "ru": "/кемпинг-лагерь/[identifier]/media",
      "sk": "/miesto-na-kempovanie/[identifier]/media",
      "sl": "/kamp/[identifier]/media",
      "sr": "/kamp-lokacija/[identifier]/media",
      "sv": "/taltplats/[identifier]/media",
      "tr": "/kamp-alani/[identifier]/media"
    },
    "campsite/[identifier]/inquire/index": {
      "de": "/campingplatz/[identifier]/anfragen",
      "en": "/campsite/[identifier]/inquire",
      "es": "/camping/[identifier]/preguntar",
      "bg": "/лагер/[identifier]/запитване",
      "bs": "/kamp-mjesto/[identifier]/raspitati-se",
      "cs": "/kemp/[identifier]/zeptat-se",
      "da": "/campinggrund/[identifier]/forhøre",
      "el": "/θεση-καμπινγκ/[identifier]/ρωτώ",
      "et": "/kamping/[identifier]/küsima",
      "fi": "/leirintaalue/[identifier]/tiedustella",
      "fr": "/emplacement-de-camping/[identifier]/renseigner",
      "hr": "/mjesto-za-kampiranje/[identifier]/raspitati",
      "hu": "/taborhely/[identifier]/érdeklődik",
      "it": "/campeggio/[identifier]/chiedere-informazioni",
      "lt": "/stovyklaviete/[identifier]/teirautis",
      "lv": "/karavanu-vieta/[identifier]/jautāt",
      "no": "/campingplass/[identifier]/spørre",
      "nl": "/kampeerplek/[identifier]/informeren",
      "pl": "/miejsce-kempingowe/[identifier]/dowiadywać-się",
      "pt": "/acampamento/[identifier]/investigar",
      "ro": "/loc-de-campare/[identifier]/întreba",
      "ru": "/кемпинг-лагерь/[identifier]/справки",
      "sk": "/miesto-na-kempovanie/[identifier]/dotazovaním",
      "sl": "/kamp/[identifier]/poizvedovati",
      "sr": "/kamp-lokacija/[identifier]/питати",
      "sv": "/taltplats/[identifier]/fråga",
      "tr": "/kamp-alani/[identifier]/sormak"
    },
    "campsite/[identifier]/inquire/confirm/index": {
      "de": "/campingplatz/[identifier]/anfragen/bestätigen",
      "en": "/campsite/[identifier]/inquire/confirm",
      "es": "/camping/[identifier]/preguntar/confirmar",
      "bg": "/лагер/[identifier]/запитване/потвърждаване",
      "bs": "/kamp-mjesto/[identifier]/raspitati-se/potvrditi",
      "cs": "/kemp/[identifier]/zeptat-se/potvrdit",
      "da": "/campinggrund/[identifier]/forhøre/bekræfte",
      "el": "/θεση-καμπινγκ/[identifier]/ρωτώ/επιβεβαιώνω",
      "et": "/kamping/[identifier]/küsima/kinnitage",
      "fi": "/leirintaalue/[identifier]/tiedustella/vahvistaa",
      "fr": "/emplacement-de-camping/[identifier]/renseigner/confirmer",
      "hr": "/mjesto-za-kampiranje/[identifier]/raspitati/potvrđuju",
      "hu": "/taborhely/[identifier]/érdeklődik/megerősít",
      "it": "/campeggio/[identifier]/chiedere-informazioni/confermare",
      "lt": "/stovyklaviete/[identifier]/teirautis/patvirtinti",
      "lv": "/karavanu-vieta/[identifier]/jautāt/apstiprināt",
      "no": "/campingplass/[identifier]/spørre/bekrefte",
      "nl": "/kampeerplek/[identifier]/informeren/bevestigen",
      "pl": "/miejsce-kempingowe/[identifier]/dowiadywać-się/potwierdzać",
      "pt": "/acampamento/[identifier]/investigar/confirme",
      "ro": "/loc-de-campare/[identifier]/întreba/a-confirma",
      "ru": "/кемпинг-лагерь/[identifier]/справки/подтверждения",
      "sk": "/miesto-na-kempovanie/[identifier]/dotazovaním/potvrdit",
      "sl": "/kamp/[identifier]/poizvedovati/potrdite",
      "sr": "/kamp-lokacija/[identifier]/питати/Потврди",
      "sv": "/taltplats/[identifier]/fråga/bekräfta",
      "tr": "/kamp-alani/[identifier]/sormak/onaylamak"
    },
    "account/index": {
      "de": "/account",
      "en": "/account",
      "es": "/account",
      "bg": "/account",
      "bs": "/account",
      "cs": "/account",
      "da": "/account",
      "el": "/account",
      "et": "/account",
      "fi": "/account",
      "fr": "/account",
      "hr": "/account",
      "hu": "/account",
      "it": "/account",
      "lt": "/account",
      "lv": "/account",
      "no": "/account",
      "nl": "/account",
      "pl": "/account",
      "pt": "/account",
      "ro": "/account",
      "ru": "/account",
      "sk": "/account",
      "sl": "/account",
      "sr": "/account",
      "sv": "/account",
      "tr": "/account"
    },
    "account/reviews/index": {
      "de": "/account/bewertungen",
      "en": "/account/reviews",
      "es": "/account/valoraciones",
      "bg": "/account/мнения",
      "bs": "/account/recenzije",
      "cs": "/account/recenze",
      "da": "/account/anmeldelser",
      "el": "/account/σχόλια",
      "et": "/account/ülevaated",
      "fi": "/account/arvostelut",
      "fr": "/account/critiques",
      "hr": "/account/recenzije",
      "hu": "/account/vélemények",
      "it": "/account/recensioni",
      "lt": "/account/atsiliepimai",
      "lv": "/account/atsauksmes",
      "no": "/account/anmeldelser",
      "nl": "/account/beoordelingen",
      "pl": "/account/recenzje",
      "pt": "/account/rever",
      "ro": "/account/comentarii",
      "ru": "/account/отзывы",
      "sk": "/account/recenzia",
      "sl": "/account/pregledi",
      "sr": "/account/mišljenja",
      "sv": "/account/recensioner",
      "tr": "/account/yorumlar"
    },
    "account/reset-password/index": {
      "de": "/account/passwort-zuruecksetzen",
      "en": "/account/reset-password",
      "es": "/account/restablecer-contraseña",
      "bg": "/account/нулиране-парола",
      "bs": "/account/resetuj-lozinku",
      "cs": "/account/obnova-hesla",
      "da": "/account/nulstil-adgangskode",
      "el": "/account/επαναφορα-κωδικου-προσβασης",
      "et": "/account/lahtesta-salasona",
      "fi": "/account/nollaa-salasana",
      "fr": "/account/reinitialiser-mot-de-passe",
      "hr": "/account/ponovno-postavi-lozinku",
      "hu": "/account/uj-jelszo-beallitasa",
      "it": "/account/reimposta-password",
      "lt": "/account/nustatyti-slaptazodi-is-naujo",
      "lv": "/account/atjaunot-paroli",
      "no": "/account/tilbakestille-passord",
      "nl": "/account/wachtwoord-herstellen",
      "pl": "/account/zresetuj-hasło",
      "pt": "/account/redefinir-senha",
      "ro": "/account/resetare-parola",
      "ru": "/account/сброс-пароля",
      "sk": "/account/obnovit-heslo",
      "sl": "/account/ponastavitev-geslo",
      "sr": "/account/resetovanje-lozinke",
      "sv": "/account/aterstall-losenord",
      "tr": "/account/parolayi-sifirla"
    },
    "account/register/index": {
      "de": "/account/registrieren",
      "en": "/account/register",
      "es": "/account/registro",
      "bg": "/account/регистрация-бланка",
      "bs": "/account/registraciona-forma",
      "cs": "/account/registracni-formular",
      "da": "/account/tilmeldings-form",
      "el": "/account/φορμα-εγγραφης",
      "et": "/account/registreerimise-vorm",
      "fi": "/account/rekisterointi-lomake",
      "fr": "/account/formulaire-d-inscription",
      "hr": "/account/obrazac-za-registraciju",
      "hu": "/account/regisztracios-urlap",
      "it": "/account/modulo-registrazione",
      "lt": "/account/registracija",
      "lv": "/account/registracijas-veidlapa",
      "no": "/account/registreringsskjema",
      "nl": "/account/registratieformulier",
      "pl": "/account/formularz-rejestracyjny",
      "pt": "/account/formulario-de-registro",
      "ro": "/account/formular-inregistrare",
      "ru": "/account/форма-регистрации",
      "sk": "/account/registracny-formular",
      "sl": "/account/registracija-obrazec",
      "sr": "/account/obrazac-registracije",
      "sv": "/account/registreringsformular",
      "tr": "/account/kayit-formu"
    },
    "account/profile/index": {
      "de": "/account/profile",
      "en": "/account/profile",
      "es": "/account/profile",
      "bg": "/account/profile",
      "bs": "/account/profile",
      "cs": "/account/profile",
      "da": "/account/profile",
      "el": "/account/profile",
      "et": "/account/profile",
      "fi": "/account/profile",
      "fr": "/account/profile",
      "hr": "/account/profile",
      "hu": "/account/profile",
      "it": "/account/profile",
      "lt": "/account/profile",
      "lv": "/account/profile",
      "no": "/account/profile",
      "nl": "/account/profile",
      "pl": "/account/profile",
      "pt": "/account/profile",
      "ro": "/account/profile",
      "ru": "/account/profile",
      "sk": "/account/profile",
      "sl": "/account/profile",
      "sr": "/account/profile",
      "sv": "/account/profile",
      "tr": "/account/profile"
    },
    "account/media/index": {
      "de": "/account/media",
      "en": "/account/media",
      "es": "/account/media",
      "bg": "/account/media",
      "bs": "/account/media",
      "cs": "/account/media",
      "da": "/account/media",
      "el": "/account/media",
      "et": "/account/media",
      "fi": "/account/media",
      "fr": "/account/media",
      "hr": "/account/media",
      "hu": "/account/media",
      "it": "/account/media",
      "lt": "/account/media",
      "lv": "/account/media",
      "no": "/account/media",
      "nl": "/account/media",
      "pl": "/account/media",
      "pt": "/account/media",
      "ro": "/account/media",
      "ru": "/account/media",
      "sk": "/account/media",
      "sl": "/account/media",
      "sr": "/account/media",
      "sv": "/account/media",
      "tr": "/account/media"
    },
    "account/media/[identifier]/index": {
      "de": "/account/media/[identifier]",
      "en": "/account/media/[identifier]",
      "es": "/account/media/[identifier]",
      "bg": "/account/media/[identifier]",
      "bs": "/account/media/[identifier]",
      "cs": "/account/media/[identifier]",
      "da": "/account/media/[identifier]",
      "el": "/account/media/[identifier]",
      "et": "/account/media/[identifier]",
      "fi": "/account/media/[identifier]",
      "fr": "/account/media/[identifier]",
      "hr": "/account/media/[identifier]",
      "hu": "/account/media/[identifier]",
      "it": "/account/media/[identifier]",
      "lt": "/account/media/[identifier]",
      "lv": "/account/media/[identifier]",
      "no": "/account/media/[identifier]",
      "nl": "/account/media/[identifier]",
      "pl": "/account/media/[identifier]",
      "pt": "/account/media/[identifier]",
      "ro": "/account/media/[identifier]",
      "ru": "/account/media/[identifier]",
      "sk": "/account/media/[identifier]",
      "sl": "/account/media/[identifier]",
      "sr": "/account/media/[identifier]",
      "sv": "/account/media/[identifier]",
      "tr": "/account/media/[identifier]"
    },
    "account/login/index": {
      "de": "/account/anmelden",
      "en": "/account/login",
      "es": "/account/iniciar-sesión",
      "bg": "/account/вписване-бланка",
      "bs": "/account/forma-za-prijavu",
      "cs": "/account/prihlasovaci-formular",
      "da": "/account/login-form",
      "el": "/account/φορμα-συνδεσης",
      "et": "/account/login-vorm",
      "fi": "/account/kirjautumis-lomake",
      "fr": "/account/formulaire-de-connexion",
      "hr": "/account/obrazac-za-prijavu",
      "hu": "/account/bejelentkezesi-urlap",
      "it": "/account/modulo-login",
      "lt": "/account/prisijungimas",
      "lv": "/account/ielogosanas-forma",
      "no": "/account/logg-inn-skjema",
      "nl": "/account/inlog-formulier",
      "pl": "/account/formularz-logowania",
      "pt": "/account/formulario-de-login",
      "ro": "/account/formular-conectare",
      "ru": "/account/форма-входа-в-систему ",
      "sk": "/account/prihlasovaci-formular",
      "sl": "/account/prijavni-obrazec",
      "sr": "/account/obrazac-prijave",
      "sv": "/account/login-formular",
      "tr": "/account/oturum-acma-formu"
    },
    "account/favorites/index": {
      "de": "/account/favorites",
      "en": "/account/favorites",
      "es": "/account/favorites",
      "bg": "/account/favorites",
      "bs": "/account/favorites",
      "cs": "/account/favorites",
      "da": "/account/favorites",
      "el": "/account/favorites",
      "et": "/account/favorites",
      "fi": "/account/favorites",
      "fr": "/account/favorites",
      "hr": "/account/favorites",
      "hu": "/account/favorites",
      "it": "/account/favorites",
      "lt": "/account/favorites",
      "lv": "/account/favorites",
      "no": "/account/favorites",
      "nl": "/account/favorites",
      "pl": "/account/favorites",
      "pt": "/account/favorites",
      "ro": "/account/favorites",
      "ru": "/account/favorites",
      "sk": "/account/favorites",
      "sl": "/account/favorites",
      "sr": "/account/favorites",
      "sv": "/account/favorites",
      "tr": "/account/favorites"
    },
    "account/confirmEmail/index": {
      "de": "/account/email-bestaetigen",
      "en": "/account/confirm-email",
      "es": "/account/confirmar-email",
      "bg": "/account/потвърждение-имейл",
      "bs": "/account/potvrdite-email",
      "cs": "/account/potvrzeni-e-mailu",
      "da": "/account/bekræft-email",
      "el": "/account/επιβεβαιωση-email",
      "et": "/account/kinnita-email",
      "fi": "/account/vahvista-sahkoposti",
      "fr": "/account/confirmer-e-mail",
      "hr": "/account/potvrdi-e-adresu",
      "hu": "/account/email-megerosites",
      "it": "/account/conferma-email",
      "lt": "/account/patvirtinti-el-pasto-adresa",
      "lv": "/account/apstiprini-e-pastu",
      "no": "/account/bekreft-e-postadresse",
      "nl": "/account/bevestiging-email",
      "pl": "/account/potwierdz-email",
      "pt": "/account/confirmar-email",
      "ro": "/account/confirmare-e-mail",
      "ru": "/account/подтвердите-электронныи-адрес",
      "sk": "/account/potvrdit-e-mail ",
      "sl": "/account/potrdite-email",
      "sr": "/account/imejl-potvrda",
      "sv": "/account/bakrafta-e-postadressen",
      "tr": "/account/e-postayi-onayla"
    },
    "account/confirm-password/index": {
      "de": "/account/neues-passwort",
      "en": "/account/new-password",
      "es": "/account/nueva-contraseña",
      "bg": "/account/потвърждение-парола",
      "bs": "/account/potvrdite-lozinku",
      "cs": "/account/potvrzeni-hesla",
      "da": "/account/bekræft-adgangskode",
      "el": "/account/επιβεβαιωση-κωδικου-προσβασης",
      "et": "/account/kinnita-salasona",
      "fi": "/account/vahvista-salasana",
      "fr": "/account/confirmer-mot-de-passe",
      "hr": "/account/potvrdi-lozinku",
      "hu": "/account/jelszo-megerosites",
      "it": "/account/conferma-password",
      "lt": "/account/patvirtinti-slaptazodi",
      "lv": "/account/apstiprini-paroli",
      "no": "/account/bekreft-passord",
      "nl": "/account/bevestiging-wachtwoord",
      "pl": "/account/potwierdz-haslo",
      "pt": "/account/confirme-a-senha",
      "ro": "/account/confirmare-parola",
      "ru": "/account/подтвердите-пароль",
      "sk": "/account/potvrdit-heslo",
      "sl": "/account/potrdite-geslo",
      "sr": "/account/potvrda-lozinke",
      "sv": "/account/bekrafta-losenord",
      "tr": "/account/sifreyi-onayla"
    },
    "account/confirm-email-change/index": {
      "de": "/account/confirm-email-change",
      "en": "/account/confirm-email-change",
      "es": "/account/confirm-email-change",
      "bg": "/account/confirm-email-change",
      "bs": "/account/confirm-email-change",
      "cs": "/account/confirm-email-change",
      "da": "/account/confirm-email-change",
      "el": "/account/confirm-email-change",
      "et": "/account/confirm-email-change",
      "fi": "/account/confirm-email-change",
      "fr": "/account/confirm-email-change",
      "hr": "/account/confirm-email-change",
      "hu": "/account/confirm-email-change",
      "it": "/account/confirm-email-change",
      "lt": "/account/confirm-email-change",
      "lv": "/account/confirm-email-change",
      "no": "/account/confirm-email-change",
      "nl": "/account/confirm-email-change",
      "pl": "/account/confirm-email-change",
      "pt": "/account/confirm-email-change",
      "ro": "/account/confirm-email-change",
      "ru": "/account/confirm-email-change",
      "sk": "/account/confirm-email-change",
      "sl": "/account/confirm-email-change",
      "sr": "/account/confirm-email-change",
      "sv": "/account/confirm-email-change",
      "tr": "/account/confirm-email-change"
    },
    "account/change-password/index": {
      "de": "/account/change-password",
      "en": "/account/change-password",
      "es": "/account/change-password",
      "bg": "/account/change-password",
      "bs": "/account/change-password",
      "cs": "/account/change-password",
      "da": "/account/change-password",
      "el": "/account/change-password",
      "et": "/account/change-password",
      "fi": "/account/change-password",
      "fr": "/account/change-password",
      "hr": "/account/change-password",
      "hu": "/account/change-password",
      "it": "/account/change-password",
      "lt": "/account/change-password",
      "lv": "/account/change-password",
      "no": "/account/change-password",
      "nl": "/account/change-password",
      "pl": "/account/change-password",
      "pt": "/account/change-password",
      "ro": "/account/change-password",
      "ru": "/account/change-password",
      "sk": "/account/change-password",
      "sl": "/account/change-password",
      "sr": "/account/change-password",
      "sv": "/account/change-password",
      "tr": "/account/change-password"
    },
    "account/change-mail/index": {
      "de": "/account/change-mail",
      "en": "/account/change-mail",
      "es": "/account/change-mail",
      "bg": "/account/change-mail",
      "bs": "/account/change-mail",
      "cs": "/account/change-mail",
      "da": "/account/change-mail",
      "el": "/account/change-mail",
      "et": "/account/change-mail",
      "fi": "/account/change-mail",
      "fr": "/account/change-mail",
      "hr": "/account/change-mail",
      "hu": "/account/change-mail",
      "it": "/account/change-mail",
      "lt": "/account/change-mail",
      "lv": "/account/change-mail",
      "no": "/account/change-mail",
      "nl": "/account/change-mail",
      "pl": "/account/change-mail",
      "pt": "/account/change-mail",
      "ro": "/account/change-mail",
      "ru": "/account/change-mail",
      "sk": "/account/change-mail",
      "sl": "/account/change-mail",
      "sr": "/account/change-mail",
      "sv": "/account/change-mail",
      "tr": "/account/change-mail"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "de",
    "name": "Deutsch",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/de.json"
      }
    ]
  },
  {
    "code": "en",
    "name": "English",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/en.json"
      }
    ]
  },
  {
    "code": "nl",
    "name": "Nederlands",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/nl.json"
      }
    ]
  },
  {
    "code": "fr",
    "name": "français",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/fr.json"
      }
    ]
  },
  {
    "code": "it",
    "name": "italiano",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/it.json"
      }
    ]
  },
  {
    "code": "es",
    "name": "Español",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/es.json"
      }
    ]
  },
  {
    "code": "bg",
    "name": "български",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/bg.json"
      }
    ]
  },
  {
    "code": "bs",
    "name": "босански",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/bs.json"
      }
    ]
  },
  {
    "code": "cs",
    "name": "čeština",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/cs.json"
      }
    ]
  },
  {
    "code": "da",
    "name": "dansk",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/da.json"
      }
    ]
  },
  {
    "code": "el",
    "name": "Ελληνικά",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/el.json"
      }
    ]
  },
  {
    "code": "et",
    "name": "eesti",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/et.json"
      }
    ]
  },
  {
    "code": "fi",
    "name": "suomi",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/fi.json"
      }
    ]
  },
  {
    "code": "hr",
    "name": "hrvatski",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/hr.json"
      }
    ]
  },
  {
    "code": "hu",
    "name": "magyar",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/hu.json"
      }
    ]
  },
  {
    "code": "lt",
    "name": "lietuvių",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/lt.json"
      }
    ]
  },
  {
    "code": "lv",
    "name": "latviešu",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/lv.json"
      }
    ]
  },
  {
    "code": "no",
    "name": "norsk bokmål",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/no.json"
      }
    ]
  },
  {
    "code": "pl",
    "name": "polski",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/pl.json"
      }
    ]
  },
  {
    "code": "pt",
    "name": "português",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/pt.json"
      }
    ]
  },
  {
    "code": "ro",
    "name": "română",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/ro.json"
      }
    ]
  },
  {
    "code": "ru",
    "name": "русский",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/ru.json"
      }
    ]
  },
  {
    "code": "sk",
    "name": "slovenčina",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/sk.json"
      }
    ]
  },
  {
    "code": "sl",
    "name": "slovenščina",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/sl.json"
      }
    ]
  },
  {
    "code": "sr",
    "name": "српски",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/sr.json"
      }
    ]
  },
  {
    "code": "sv",
    "name": "svenska",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/sv.json"
      }
    ]
  },
  {
    "code": "tr",
    "name": "Türkçe",
    "files": [
      {
        "path": "/builds/campinginfo/civ2-nuxt-3/app/locales/dist/dummy/tr.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
