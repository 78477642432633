import { sortWith, prop, ascend, compose, toLower } from 'ramda';

export const sortArrayOfObjectsByKeyAlphabetically = (key) => {
  return sortWith([ascend(compose(toLower, prop(key)))]);
};

export const fixImageUrl = (backendUrl, url) => {
  if (url === null || url === undefined || typeof url !== 'string') {
    return url;
  }
  if (url && url.match(/^\/.+\.[a-z0-9]{3,5}/i)) {
    return `${backendUrl}${url}`;
  }
  return url;
};

export const addWebp = (url, webp = true) => {
  if (webp && (url.match(/camping\.info\//) || url.match(/http:\/\/localhost/))) {
    return `${url}.webp`;
  }
  return url;
};

export const makeAbsoluteUrlRelative = (url, base) => {
  if (!url) {
    return url;
  }
  const ciBaseUrl = 'https://www.camping.info';
  const baseUrl = base || ciBaseUrl;
  if (url.toLowerCase().startsWith(baseUrl)) {
    return url.substr(baseUrl.length);
  }
  if (url.toLowerCase().startsWith(ciBaseUrl)) {
    return url.substr(ciBaseUrl.length);
  }
  return url;
};

export const isAnchorUrl = (url = '') => {
  if (!url) {
    return false;
  }

  const m = url.match(/#([a-z0-9_-]+)$/i);
  return m && m[1] !== 'availabilities';
};

export const isAbsoluteUrl = (url = '') => {
  if (!url) {
    return false;
  }

  return !!url.match(/^(?:(?:https?:\/\/)|(?:\/\/))/i);
};

export const isInternalUrl = (url, base) => {
  if (!url) {
    return false;
  }

  if (!isAbsoluteUrl(url)) {
    return true;
  }

  let baseUrl = base;
  const m1 = baseUrl.match(/^\/\/(localhost.*)$/);
  const m2 = baseUrl.match(/^\/\/(.+)$/);
  if (m1) {
    baseUrl = `http://${m1[1]}`;
  } else if (m2) {
    baseUrl = `https://${m2[1]}`;
  }

  return url.startsWith(baseUrl);
};

export const mapMedia = (backendUrl, mediaItems, pageParam = 1) => {
  const currentMediaIndex = parseInt(pageParam * 10, 10) - 10;

  return mediaItems.map((item, index) => {
    item.thumb = fixImageUrl(backendUrl, item.thumb);
    item.map = fixImageUrl(backendUrl, item.map);
    item.normal = fixImageUrl(backendUrl, item.normal);
    item.large = fixImageUrl(backendUrl, item.large);
    return { mediaNumber: index + currentMediaIndex + 1, ...item };
  });
};

// https://stackoverflow.com/questions/948172/password-strength-meter
export const scorePassword = (pass) => {
  let score = 0;
  if (!pass) {
    return score;
  }

  // award every unique letter until 5 repetitions
  const letters = {};

  for (let i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1;
    score += 5.0 / letters[pass[i]];
  }

  // bonus points for mixing it up
  const variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass),
  };

  let variationCount = 0;

  for (const check in variations) {
    variationCount += variations[check] === true ? 1 : 0;
  }
  score += (variationCount - 1) * 10;
  score = parseInt(score, 10);

  if (score > 90) {
    return 4;
  }
  if (score > 80) {
    return 3;
  }
  if (score > 70) {
    return 2;
  }
  if (score > 60) {
    return 1;
  }
  return 0;
};

export const getOffsetTop = (element) => {
  return element.getBoundingClientRect().top + window.scrollY;
};

export const scrollToElement = (scope, elementClassName) => {
  const element = scope.getElementsByClassName(elementClassName);
  if (element && element[0]) {
    element[0].scrollIntoView({ behavior: 'smooth' });
  }
};

export const isInViewport = (element) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0
    && rect.left >= 0
    && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const nl2br = txt => txt.replace(/\s*\n\s*\n+\s*/g, '<br /><br />').replace(/\s*\n\s*/g, '<br />');

export const htmlEscape = (str) => {
  return str.replace(/[\u00A0-\u9999<>&]/g, (i) => {
    return '&#' + i.charCodeAt(0) + ';';
  });
};

export const escapeHtmlAttribute = (str) => {
  const map = { '&': '&amp;', '<': '&lt;', '>': '&gt;', '"': '&quot;', '\'': '&#39;' };
  return str.replace(/[&<>"']/g, c => map[c]);
};
