import type { UserProfile } from '@/types';

import useFetchService from '@/composables/useFetchService';

export default function useUserService() {
  /********************
   * COMPOSITIONS      *
   ********************/
  const runtimeConfig = useRuntimeConfig();
  const fetchService = useFetchService({ base: runtimeConfig.public.apiUrl });

  /********************
   * FUNCTIONS         *
   ********************/
  function canCreateCampsiteMedia(lang: string) {
    return fetchService.get('/account/check-permission/create_campsite_media/', lang);
  }

  function changeMail(lang: string, data) {
    return fetchService.post('/account/email/change/', lang, data);
  }

  function changePassword(lang: string, data) {
    return fetchService.post('/account/password/change/', lang, data);
  }

  function checkUser(lang: string, params) {
    return fetchService.get('/account/check-user/', lang, { params });
  }

  function getMedia(lang: string, params) {
    return fetchService.get('/account/profile/media/', lang, { params });
  }

  function getMediaForCampsite(lang: string, slug: string) {
    return fetchService.get(`/account/profile/media/${slug}/`, lang);
  }

  function getProfile(lang: string, params?: any) {
    return fetchService.get('/account/profile/', lang, { params });
  }
  function getProfileLanguages(lang) {
    return fetchService.get('/account/profile/languages/', lang);
  }
  function getReviews(lang: string, params) {
    return fetchService.get('/account/profile/reviews/', lang, { params });
  }
  function login(lang: string, data) {
    return fetchService.post<UserProfile>('/account/login/', lang, data);
  }
  function logout() {
    return fetchService.post('/account/logout/', null);
  }
  function register(lang: string, data) {
    return fetchService.post('/account/registration/', lang, data);
  }
  function resendVerificationEmail(lang: string, data) {
    return fetchService.post('/account/registration/resend-verification-email/', lang, data);
  }
  function resetPassword(lang: string, data) {
    return fetchService.post('/account/password/reset/', lang, data);
  }
  function setNewPassword(lang: string, data) {
    return fetchService.post('/account/password/reset/confirm/', lang, data);
  }
  function setProfile(lang: string, data) {
    return fetchService.post('/account/profile/update/', lang, data);
  }
  function setProfilePicture(lang: string, data) {
    return fetchService.post('/account/profile/picture/', lang, data);
  }
  function toggleFavorite(data) {
    return fetchService.post('/account/profile/toggle-favorite/', null, data);
  }
  function update(lang: string, data) {
    return fetchService.post('/account/profile/', lang, data);
  }
  function verifyChangedEmail(lang: string, params) {
    return fetchService.get('/account/email/change/confirm/', lang, { params });
  }
  function verifyEmail(lang: string, data) {
    return fetchService.post('/account/registration/verify-email/', lang, data);
  }

  return {
    canCreateCampsiteMedia,
    changeMail,
    changePassword,
    checkUser,
    getMedia,
    getMediaForCampsite,
    getProfile,
    getProfileLanguages,
    getReviews,
    login,
    logout,
    register,
    resendVerificationEmail,
    resetPassword,
    setNewPassword,
    setProfile,
    setProfilePicture,
    toggleFavorite,
    update,
    verifyChangedEmail,
    verifyEmail,
  };
}
