import { computeDyndates } from '@/utils/dyndates';

export default defineNuxtRouteMiddleware((to) => {
  if (to.query.from === '2023-07-28' && to.query.until === '2023-07-30') {
    to.query.dyndates = 'sa,1w,1w';
  }
  const calculatedDates = computeDyndates(to.query.dyndates);
  if (calculatedDates) {
    const query = { ...to.query };
    delete query.from;
    delete query.until;
    delete query.dyndates;
    return navigateTo({ path: to.path, query: { ...query, ...calculatedDates }, hash: to.hash }, { redirectCode: 302 });
  }
});
