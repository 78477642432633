const availableLanguages: Array<{ code: string; name: string; file: string }> = [
  {
    code: 'de',
    name: 'Deutsch',
    file: 'de.json',
  },
  {
    code: 'en',
    name: 'English',
    file: 'en.json',
  },
  {
    code: 'nl',
    name: 'Nederlands',
    file: 'nl.json',
  },
  {
    code: 'fr',
    name: 'français',
    file: 'fr.json',
  },
  {
    code: 'it',
    name: 'italiano',
    file: 'it.json',
  },
  {
    code: 'es',
    name: 'Español',
    file: 'es.json',
  },
  {
    code: 'bg',
    name: 'български',
    file: 'bg.json',
  },
  {
    code: 'bs',
    name: 'босански',
    file: 'bs.json',
  },
  {
    code: 'cs',
    name: 'čeština',
    file: 'cs.json',
  },
  {
    code: 'da',
    name: 'dansk',
    file: 'da.json',
  },
  {
    code: 'el',
    name: 'Ελληνικά',
    file: 'el.json',
  },
  {
    code: 'et',
    name: 'eesti',
    file: 'et.json',
  },
  {
    code: 'fi',
    name: 'suomi',
    file: 'fi.json',
  },
  {
    code: 'hr',
    name: 'hrvatski',
    file: 'hr.json',
  },
  {
    code: 'hu',
    name: 'magyar',
    file: 'hu.json',
  },
  {
    code: 'lt',
    name: 'lietuvių',
    file: 'lt.json',
  },
  {
    code: 'lv',
    name: 'latviešu',
    file: 'lv.json',
  },
  {
    code: 'no',
    name: 'norsk bokmål',
    file: 'no.json',
  },
  {
    code: 'pl',
    name: 'polski',
    file: 'pl.json',
  },
  {
    code: 'pt',
    name: 'português',
    file: 'pt.json',
  },
  {
    code: 'ro',
    name: 'română',
    file: 'ro.json',
  },
  {
    code: 'ru',
    name: 'русский',
    file: 'ru.json',
  },
  {
    code: 'sk',
    name: 'slovenčina',
    file: 'sk.json',
  },
  {
    code: 'sl',
    name: 'slovenščina',
    file: 'sl.json',
  },
  {
    code: 'sr',
    name: 'српски',
    file: 'sr.json',
  },
  {
    code: 'sv',
    name: 'svenska',
    file: 'sv.json',
  },
  {
    code: 'tr',
    name: 'Türkçe',
    file: 'tr.json',
  },
];

export default availableLanguages;
