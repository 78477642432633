export default async function useContent() {
  /********************
   * COMPOSITIONS      *
   ********************/
  const contentStore = useContentStore();
  const getRouteBaseName = useRouteBaseName();
  const localeRoute = useLocaleRoute();
  const route = useRoute();
  const router = useRouter();
  const seoStore = useSeoStore();
  const { fetchError } = useFetchError();
  const { locale } = useI18n();
  const { routes } = useAppStore();

  /********************
   * REFS & VARS       *
   ********************/
  const lastPath = ref<string | null>(null);

  /********************
   * HOOKS             *
   ********************/
  watch(
    () => router.currentRoute.value.path,
    (_, oldValue) => {
      lastPath.value = oldValue;
    },
  );

  /********************
   * INITIALIZATION    *
   ********************/
  await useLazyAsyncData(`content-${route.path}`, getContent, {
    watch: [() => route.path],
  });

  /********************
   * FUNCTIONS         *
   ********************/
  async function handleDynamicPageContent() {
    const dynamicPageInfo = identifyDynamicPage(locale.value, routes, router.currentRoute.value, router);
    if (!dynamicPageInfo || dynamicPageInfo.redirect) {
      return null;
    } else if (dynamicPageInfo.path) {
      // --------------------------------------------------------------------
      // see rule for content url in mixin/seo.js
      // --------------------------------------------------------------------
      const content = await contentStore.getContent({
        url: `${dynamicPageInfo.path}${cleanAndSortParams(getRouteBaseName(dynamicPageInfo), dynamicPageInfo.query, true)}`,
        lang: dynamicPageInfo.lang,
      });
      return content;
    }
    return null;
  }

  async function getContent() {
    contentStore.clearContent();
    seoStore.alternateTags = [];

    const routeBaseName = getRouteBaseName(router.currentRoute.value);

    const isCitySearch = routeBaseName === RBN_CITY_SEARCH;
    const isCountrySearch = routeBaseName === RBN_COUNTRY_SEARCH;
    const isFederalState = routeBaseName === RBN_FEDERAL_STATE_SEARCH;
    const isMapSearch = routeBaseName === RBN_SEARCH_MAP;
    const isRegionSearch = routeBaseName === RBN_REGION_SEARCH;
    const isSearch = routeBaseName === RBN_SEARCH;

    const isSearchPage = [
      isCitySearch,
      isCountrySearch,
      isFederalState,
      isRegionSearch,
      isMapSearch,
      isSearch,
    ].includes(true);

    // if we use dynamicMetaParams on another pages, this needs to be updated
    if (!routeBaseName?.startsWith('campsite-identifier') && !isSearchPage) {
      seoStore.setDynamicMetaParam(toRaw(seoStore.seo));
    }

    // do not fetch content for search pages
    if (isSearchPage || (import.meta.client && lastPath.value === router.currentRoute.value.fullPath)) {
      return contentStore.contents;
    }

    // do not fetch content for numeric campsite urls, because we redirect to slug.
    if (routeBaseName === RBN_CAMPSITE_DETAIL && route.params?.identifier?.match(/^\d+$/)) {
      return null;
    }

    // Dynamic Pages handle content fetching on their own
    if (isDynamicPage(routeBaseName)) {
      const result = await handleDynamicPageContent();
      if (result === null) {
        fetchError({ response: { statusCode: 404 }, message: 'Dynamic content not found' });
      }
      return result;
    }

    // --------------------------------------------------------------------
    // see rule for content url in mixin/seo.js
    // --------------------------------------------------------------------
    const englishRoute = routeBaseName && localeRoute(routeBaseName, 'en');
    if (englishRoute) {
      const content = await contentStore.getContent({
        url: `${englishRoute?.path}${cleanAndSortParams(getRouteBaseName(englishRoute), englishRoute)}`,
        lang: locale.value,
      });
      return content;
    }

    return null;
  }

  return {
    handleDynamicPageContent,
  };
}
