import { defineStore, acceptHMRUpdate } from 'pinia';

import type { UserProfile, ProfileLanguage } from '@/types';

// Stores
import { useLoadingStore } from '@/stores/loading';

// Utils
import { deleteCookie, setCookie, getUserLoginToken, removeUserLoginToken, setUserLoginToken } from '@/utils/cookies';

import { USER_DATA_TOKEN, USER_LOGIN_DEFAULT_COOKIE_LIFETIME } from '@/utils/constants';

export const useUserStore = defineStore('user', () => {
  /********************
   * COMPOSITIONS      *
   ********************/
  const userService = useUserService();
  const { setStatusError, setStatusLoading, setStatusSuccess } = useLoadingStore();

  /********************
   * REFS & VARS       *
   ********************/
  const user = reactive<{
    languages: ProfileLanguage[] | null;
    profile: UserProfile | null;
    token: UserProfile['key'] | null;
  }>({
    languages: [],
    profile: null,
    token: null,
  });

  const favoritePending = ref<string | boolean>(false);

  const favorites = computed(() => {
    return user.profile?.favorites || [];
  });

  const loggedIn = computed(() => {
    return user.token !== null;
  });

  /********************
   * FUNCTIONS         *
   ********************/
  function init(lang: string) {
    const token = getUserLoginToken() || null;

    if (token) {
      saveToken({ token, persistance: true });
      loadUser(lang);
    } else {
      deleteUser();
    }
  }

  function loadUser(lang: string) {
    if (user.token) {
      userService
        .getProfile(lang)
        .then((response) => {
          user.profile = response;
        })
        .catch(() => {
          logout();
        });
    }
  }

  function saveUser(payload: { user: UserProfile; persistance: boolean }) {
    user.profile = payload.user;
    setCookie(USER_DATA_TOKEN, JSON.stringify(payload.user), {
      expires: payload.persistance ? 10 * 356 : USER_LOGIN_DEFAULT_COOKIE_LIFETIME,
    });
  }

  function saveToken(payload: { token: string; persistance: boolean }) {
    user.token = payload.token;
    const expires = payload.persistance ? 10 * 365 : USER_LOGIN_DEFAULT_COOKIE_LIFETIME;
    setUserLoginToken(payload.token, expires);
  }

  function deleteUser() {
    deleteCookie(USER_DATA_TOKEN);
    removeUserLoginToken();
    user.languages = null;
    user.profile = null;
    user.token = null;
  }

  async function login({
    lang,
    email,
    password,
    remember,
  }: {
    lang: string;
    email: string;
    password: string;
    remember: boolean;
  }) {
    setStatusLoading('login');
    try {
      const response = await userService.login(lang, { email, password });
      if (response) {
        saveToken({
          token: response.key,
          persistance: remember,
        });
        saveUser({
          user: response,
          persistance: remember,
        });
        setStatusSuccess('login');
      }
      return response;
    } catch (error) {
      setStatusError({ name: 'login', error });
      deleteUser();
      throw error;
    }
  }

  async function logout() {
    setStatusLoading('logout');

    try {
      const response = await userService.logout();
      deleteUser();
      setStatusSuccess('logout');
      return response;
    } catch (error) {
      setStatusError({ name: 'logout', error });
      throw error;
    }
  }

  async function addFavorite(slug: string) {
    setStatusLoading('addFavorite');

    if (!user.profile) {
      throw new Error('no user');
    }

    try {
      const response = await userService.toggleFavorite({ slug, toggle: 1 });
      user.profile.favorites = response.favorites;
      setStatusSuccess('addFavorite');
      return response;
    } catch (error) {
      setStatusError({ name: 'addFavorite', error });
      throw error;
    }
  }

  async function removeFavorite(slug: string) {
    setStatusLoading('removeFavorite');

    if (!user.profile) {
      throw new Error('no user');
    }

    try {
      const response = await userService.toggleFavorite({ slug, toggle: 0 });
      user.profile.favorites = response.favorites;
      setStatusSuccess('removeFavorite');
      return response;
    } catch (error) {
      setStatusError({ name: 'removeFavorite', error });
      throw error;
    }
  }

  async function getUserReviews({ lang, params }: { lang: string; params: any }) {
    setStatusLoading('getUserReviews');

    try {
      const response = await userService.getReviews(lang, params);
      setStatusSuccess('getUserReviews');
      return response;
    } catch (error) {
      setStatusError({ name: 'getUserReviews', error });
      throw error;
    }
  }

  async function getUserMedia({ lang, params }: { lang: string; params: any }) {
    setStatusLoading('getUserMedia');

    try {
      const response = await userService.getMedia(lang, params);
      setStatusSuccess('getUserMedia');
      return response;
    } catch (error) {
      setStatusError({ name: 'getUserMedia', error });
      throw error;
    }
  }

  async function getUserMediaForCampsite({ lang, slug }: { lang: string; slug: string }) {
    setStatusLoading('getUserMediaForCampsite');

    try {
      const response = await userService.getMediaForCampsite(lang, slug);
      setStatusSuccess('getUserMediaForCampsite');
      return response;
    } catch (error) {
      setStatusError({ name: 'getUserMediaForCampsite', error });
      throw error;
    }
  }

  async function setProfilePicture({ lang, data }: { lang: string; data: any }) {
    setStatusLoading('setProfilePicture');

    try {
      const response = await userService.setProfilePicture(lang, data);
      setStatusSuccess('setProfilePicture');
      user.profile = { ...user.profile, images: response };
      return response;
    } catch (error) {
      setStatusError({ name: 'setProfilePicture', error });
      throw error;
    }
  }

  async function setProfile({ lang, data }: { lang: string; data: any }) {
    setStatusLoading('setProfile');

    try {
      const response = await userService.setProfile(lang, data);
      setStatusSuccess('setProfile');
      user.profile = { ...user.profile, ...response };
      return response;
    } catch (error) {
      setStatusError({ name: 'setProfile', error });
      throw error;
    }
  }

  async function getProfileLanguages({ lang }: { lang: string }) {
    if (user.languages?.length) {
      return;
    }

    setStatusLoading('getProfileLanguages');

    try {
      const response = await userService.getProfileLanguages(lang);
      user.languages = response;
      setStatusSuccess('getProfileLanguages');
      return response;
    } catch (error) {
      setStatusError({ name: 'getProfileLanguages', error });
      throw error;
    }
  }

  return {
    addFavorite,
    deleteUser,
    favoritePending,
    favorites,
    getProfileLanguages,
    getUserMedia,
    getUserMediaForCampsite,
    getUserReviews,
    init,
    loadUser,
    loggedIn,
    login,
    logout,
    removeFavorite,
    saveToken,
    saveUser,
    setProfile,
    setProfilePicture,
    user,
  };
});

export type UserStore = ReturnType<typeof useUserStore>;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
