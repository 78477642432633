import { $pgettext } from '@/utils/gettext';

import type { RouteMetaData } from '@/types';

// constants
import {
  RBN_ACCOUNT_MEDIA,
  RBN_ACCOUNT_MEDIA_DETAIL,
  RBN_ACCOUNT_PROFILE,
  RBN_ACCOUNT_REVIEWS,
  RBN_CAMPSITE_DETAIL,
  RBN_CITY_SEARCH,
  RBN_COUNTRY_SEARCH,
  RBN_FAVORITES,
  RBN_FEDERAL_STATE_SEARCH,
  RBN_IMAGE_UPLOAD,
  RBN_LOGIN,
  RBN_REGION_SEARCH,
  RBN_VIDEO_UPLOAD,
} from '@/utils/constants';

export const metaData: Record<string, RouteMetaData> = {
  'index': {
    defaultTitle: {
      'country_count image_count num_campsites review_count': $pgettext(
        'default text meta tag title',
        'Your Guide to Camping in Europe',
      ),
    },
    defaultDescription: $pgettext(
      'default text meta tag description',
      'Made with ♥ Find the perfect campsite for you with our market leading platform in %{ country_count } countries ✓ %{ num_campsites } campsites ✓ %{ review_count } reviews ✓ %{ image_count } images ✓ Discover now!',
    ),
    defaultH1: $pgettext(
      'default text h1',
      '<span class="text-bold">Discover %{ num_campsites } campsites</span><br /> across Europe',
    ),
  },

  // See ErrorPage.vue for specific http status meta data
  'error': {
    defaultTitle: $pgettext('default text meta tag title', 'An error occurred'),
    defaultDescription: '',
    defaultH1: $pgettext('default text h1', 'Oh no, something went wrong!'),
  },

  'searchMap': {
    defaultTitle: $pgettext('default text meta tag title', 'Your Guide to Camping in Europe'),
    defaultDescription: '',
    defaultH1: '',
  },

  [RBN_FAVORITES]: {
    defaultTitle: $pgettext('default text meta tag title', 'bookmarks-title'),
    defaultDescription: $pgettext('default text meta tag description', 'bookmarks-description'),
    defaultH1: $pgettext('default text h1', 'Your bookmarks'),
  },

  'campsite': {
    defaultTitle: {
      'resultCount searchTerm': $pgettext('default text meta tag title', 'Camping and Campsites'),
    },
    defaultDescription: '',
    defaultH1: $pgettext('default text h1', 'Camping and Campsites'),
  },

  [RBN_CAMPSITE_DETAIL]: {
    defaultTitle: {
      'campsite_city campsite_country campsite_federalstate campsite_imagecount campsite_name campsite_rating campsite_reviewcount custom_description':
        $pgettext('default text meta tag title', 'Campsite %{ campsite_name } in %{ campsite_federalstate }'),
    },
    defaultDescription: $pgettext(
      'default text meta tag description',
      'Campsite %{ campsite_name } in %{ campsite_federalstate } ♥ See %{ campsite_reviewcount } camper reviews with an overall rating of %{ campsite_rating } ✓ Check out %{ campsite_imagecount } pictures taken of this campsite ✓ Discover this campsite now!',
    ),
    defaultH1: $pgettext('default text h1', '%{ campsite_name }'),
  },

  'campsite-identifier-reviews': {
    defaultTitle: {
      'campsite_city campsite_country campsite_federalstate campsite_imagecount campsite_name campsite_rating campsite_reviewcount custom_description':
        $pgettext('default text meta tag title', 'Campsite %{ campsite_name } in %{ campsite_federalstate } Reviews'),
    },
    defaultDescription: $pgettext(
      'default text meta tag description',
      'Campsite %{ campsite_name } in %{ campsite_federalstate } ♥ See %{ campsite_reviewcount } camper reviews with an overall rating of %{ campsite_rating } ✓ Check out %{ campsite_imagecount } pictures taken of this campsite ✓ Discover this campsite now!',
    ),
    defaultH1: $pgettext('default text h1', '%{ campsite_name } Reviews'),
  },

  'campsite-identifier-reviews-id': {
    defaultTitle: {
      'campsite_city campsite_country campsite_federalstate campsite_imagecount campsite_name campsite_rating campsite_reviewcount custom_description':
        $pgettext('default text meta tag title', 'Campsite %{ campsite_name } in %{ campsite_federalstate } Reviews'),
    },
    defaultDescription: $pgettext(
      'default text meta tag description',
      'Campsite %{ campsite_name } in %{ campsite_federalstate } ♥ See %{ campsite_reviewcount } camper reviews with an overall rating of %{ campsite_rating } ✓ Check out %{ campsite_imagecount } pictures taken of this campsite ✓ Discover this campsite now!',
    ),
    defaultH1: $pgettext('default text h1', '%{ campsite_name } Reviews'),
  },

  'campsite-identifier-media': {
    defaultTitle: {
      campsite_name: $pgettext('default text meta tag title', '%{ campsite_name }'),
    },
    defaultDescription: '',
    defaultH1: $pgettext('default text h1', '%{ campsite_name } Media'),
  },

  'campsite-identifier-upload': {
    defaultTitle: $pgettext('default text meta tag title', 'campsite-detail-title'),
    defaultDescription: $pgettext('default text meta tag description', 'campsite-detail-description'),
  },

  [RBN_IMAGE_UPLOAD]: {
    defaultTitle: {
      campsite_name: $pgettext('default text meta tag title', 'Upload images for %{ campsite_name }'),
    },
    defaultDescription: '',
    defaultH1: '',
  },

  [RBN_VIDEO_UPLOAD]: {
    defaultTitle: {
      campsite_name: $pgettext('default text meta tag title', 'Upload videos for %{ campsite_name }'),
    },
    defaultDescription: '',
    defaultH1: '',
  },

  'campsite-identifier-rate': {
    defaultTitle: {
      campsite_name: $pgettext('default text meta tag title', 'Rate %{ campsite_name }'),
    },
    defaultDescription: '',
    defaultH1: '',
  },

  'campsite-identifier-rating-widget': {
    defaultTitle: {
      campsite_name: $pgettext('default text meta tag title', '%{ campsite_name }'),
    },
    defaultDescription: '',
    defaultH1: '',
  },

  'campsite-identifier-inquire': {
    defaultTitle: {
      campsite_name: $pgettext('default text meta tag title', 'Inquire %{ campsite_name }'),
    },
    defaultDescription: '',
    defaultH1: '',
  },

  'campsite-identifier-inquire-confirm': {
    defaultTitle: $pgettext('default text meta tag title', 'Inquire confirmation'),
    defaultDescription: '',
    defaultH1: '',
  },

  'search': {
    defaultTitle: {
      'searchTerm': $pgettext('default text meta tag title', 'Campsites for %{ searchTerm }'),
      'resultCount searchTerm': $pgettext('default text meta tag title', 'Campsites for %{ searchTerm }'),
    },
    defaultDescription: '',
    defaultH1: $pgettext('default text h1', '<span class="text-medium">Campsites</span> for %{ searchTerm }'),
  },

  [RBN_COUNTRY_SEARCH]: {
    defaultTitle: {
      'searchTerm': $pgettext('default text meta tag title', 'Camping in %{ searchTerm }'),
      'lowest_price picture_count resultCount review_count searchTerm': $pgettext(
        'default text meta tag title',
        'Camping in %{ searchTerm }',
      ),
      'picture_count resultCount review_count searchTerm': $pgettext(
        'default text meta tag title',
        'Camping in %{ searchTerm }',
      ),
    },
    defaultDescription: {
      'resultCount>5': $pgettext(
        'default text meta tag description',
        'Find the right campground for you from %{resultCount} campsites in %{searchTerm}, %{review_count} camper reviews and %{picture_count} photos. ✓ Book online now!',
      ),
      'resultCount<=5': $pgettext(
        'default text meta tag description',
        'Camping in %{searchTerm}. All important information and available campsites clearly prepared. ✓ Book online now!',
      ),
    },
    defaultH1: $pgettext('default text h1', '<span class="text-medium">Camping</span> in %{ searchTerm }'),
  },

  [RBN_CITY_SEARCH]: {
    defaultTitle: {
      'searchTerm': $pgettext('default text meta tag title', 'Camping in %{ searchTerm }'),
      'lowest_price picture_count resultCount review_count searchTerm': $pgettext(
        'default text meta tag title',
        'Camping in %{ searchTerm }',
      ),
      'picture_count resultCount review_count searchTerm': $pgettext(
        'default text meta tag title',
        'Camping in %{ searchTerm }',
      ),
    },
    defaultDescription: {
      'resultCount>5': $pgettext(
        'default text meta tag description',
        'Find the right campground for you from %{resultCount} campsites in %{searchTerm}, %{review_count} camper reviews and %{picture_count} photos. ✓ Book online now!',
      ),
      'resultCount<=5': $pgettext(
        'default text meta tag description',
        'Camping in %{searchTerm}. All important information and available campsites clearly prepared. ✓ Book online now!',
      ),
    },
    defaultH1: $pgettext('default text h1', '<span class="text-medium">Camping</span> in %{ searchTerm }'),
  },

  [RBN_FEDERAL_STATE_SEARCH]: {
    defaultTitle: {
      'searchTerm': $pgettext('default text meta tag title', 'Camping in %{ searchTerm }'),
      'lowest_price picture_count resultCount review_count searchTerm': $pgettext(
        'default text meta tag title',
        'Camping in %{ searchTerm }',
      ),
      'picture_count resultCount review_count searchTerm': $pgettext(
        'default text meta tag title',
        'Camping in %{ searchTerm }',
      ),
    },
    defaultDescription: {
      'resultCount>5': $pgettext(
        'default text meta tag description',
        'Find the right campground for you from %{resultCount} campsites in %{searchTerm}, %{review_count} camper reviews and %{picture_count} photos. ✓ Book online now!',
      ),
      'resultCount<=5': $pgettext(
        'default text meta tag description',
        'Camping in %{searchTerm}. All important information and available campsites clearly prepared. ✓ Book online now!',
      ),
    },
    defaultH1: $pgettext('default text h1', '<span class="text-medium">Camping</span> in %{ searchTerm }'),
  },

  [RBN_REGION_SEARCH]: {
    defaultTitle: {
      'searchTerm': $pgettext('default text meta tag title', 'Camping %{ searchTerm }'),
      'lowest_price picture_count resultCount review_count searchTerm': $pgettext(
        'default text meta tag title',
        'Camping %{ searchTerm }',
      ),
      'picture_count resultCount review_count searchTerm': $pgettext(
        'default text meta tag title',
        'Camping %{ searchTerm }',
      ),
    },
    defaultDescription: {
      'resultCount>5': $pgettext(
        'default text meta tag description',
        'Find the right campsite for you from %{resultCount} campsites in the region %{searchTerm}, %{review_count} camper reviews and %{picture_count} photos. ✓ Book online now!',
      ),
      'resultCount<=5': $pgettext(
        'default text meta tag description',
        'Camping in the region %{searchTerm}. All important info and more available campsites clearly prepared. ✓ Book online now!',
      ),
    },
    defaultH1: $pgettext('default text h1', '<span class="text-medium">Camping</span> %{ searchTerm }'),
  },

  'account': {
    defaultTitle: '',
    defaultDescription: '',
    defaultH1: '',
  },

  [RBN_ACCOUNT_PROFILE]: {
    defaultTitle: $pgettext('default text meta tag title', 'My profile'),
    defaultDescription: '',
    defaultH1: '',
  },

  [RBN_ACCOUNT_REVIEWS]: {
    defaultTitle: $pgettext('default text meta tag title', 'My reviews'),
    defaultDescription: '',
    defaultH1: '',
  },

  [RBN_ACCOUNT_MEDIA]: {
    defaultTitle: $pgettext('default text meta tag title', 'My pictures & videos'),
    defaultDescription: '',
    defaultH1: '',
  },

  [RBN_ACCOUNT_MEDIA_DETAIL]: {
    defaultTitle: $pgettext('default text meta tag title', 'My pictures & videos'),
    defaultDescription: '',
    defaultH1: '',
  },

  'account-change-mail': {
    defaultTitle: $pgettext('default text meta tag title', 'Change your email address'),
    defaultDescription: '',
    defaultH1: '',
  },

  'account-confirm-email-change': {
    defaultTitle: $pgettext('default text meta tag title', 'Please confirm your new email address'),
    defaultDescription: '',
    defaultH1: '',
  },

  'account-change-password': {
    defaultTitle: $pgettext('default text meta tag title', 'Change your password'),
    defaultDescription: '',
    defaultH1: '',
  },

  [RBN_LOGIN]: {
    defaultTitle: $pgettext('default text meta tag title', 'Login'),
    defaultDescription: $pgettext('default text meta tag description', 'Login to camping.info'),
    defaultH1: '',
  },

  'account-reset-password': {
    defaultTitle: $pgettext('default text meta tag title', 'Reset your password'),
    defaultDescription: $pgettext('default text meta tag description', 'Reset your password for camping.info'),
    defaultH1: '',
  },

  'account-confirm-password': {
    defaultTitle: $pgettext('default text meta tag title', 'Confirm your Email'),
    defaultDescription: '',
    defaultH1: '',
  },

  'account-register': {
    defaultTitle: $pgettext('default text meta tag title', 'Register'),
    defaultDescription: $pgettext('default text meta tag description', 'Register to camping.info'),
    defaultH1: '',
  },

  'account-confirmEmail': {
    defaultTitle: $pgettext('default text meta tag title', 'Confirm your Email'),
    defaultDescription: $pgettext('default text meta tag description', 'Confirm your Email for camping.info'),
    defaultH1: '',
  },

  'country': {
    defaultTitle: $pgettext('default text meta tag title', 'Popular countries for campsites'),
    defaultDescription: $pgettext(
      'default text meta tag description',
      'Made with ♥ Find the perfect campsite for you with our market leading platform in 20+ popular countries ✓ 23.000+ campsites ✓ 170.000+ reviews ✓ 240.000+ images ✓ Discover now!',
    ),
    defaultH1: $pgettext('default text h1', 'Popular countries for campsites'),
  },

  'popularRegion': {
    defaultTitle: $pgettext('default text meta tag title', 'Popular regions for campsites'),
    defaultDescription: $pgettext(
      'default text meta tag description',
      'Made with ♥ Find the perfect campsite for you with our market leading platform in 2000+ popular regions ✓ 23.000+ campsites ✓ 170.000+ reviews ✓ 240.000+ images ✓ Discover now!',
    ),
    defaultH1: $pgettext('default text h1', 'Popular regions for campsites'),
  },

  'info': {
    defaultTitle: {
      'country_count image_count num_campsites review_count': $pgettext(
        'default text meta tag title',
        'Your Guide to Camping in Europe',
      ),
    },
    defaultDescription: $pgettext(
      'default text meta tag description',
      'Made with ♥ Find the perfect campsite for you with our market leading platform in %{ country_count } countries ✓ %{ num_campsites } campsites ✓ %{ review_count } reviews ✓ %{ image_count } images ✓ Discover now!',
    ),
    defaultH1: $pgettext('default text h1', 'Everything about camping.info'),
  },

  'info-all': {
    defaultTitle: {
      'country_count image_count num_campsites review_count': $pgettext(
        'default text meta tag title',
        'Your Guide to Camping in Europe',
      ),
    },
    defaultDescription: $pgettext(
      'default text meta tag description',
      'Made with ♥ Find the perfect campsite for you with our market leading platform in %{ country_count } countries ✓ %{ num_campsites } campsites ✓ %{ review_count } reviews ✓ %{ image_count } images ✓ Discover now!',
    ),
    defaultH1: '',
  },

  'sea-all': {
    defaultTitle: {
      'country_count image_count num_campsites review_count': $pgettext(
        'default text meta tag title',
        'Your Guide to Camping in Europe',
      ),
    },
    defaultDescription: $pgettext(
      'default text meta tag description',
      'Made with ♥ Find the perfect campsite for you with our market leading platform in %{ country_count } countries ✓ %{ num_campsites } campsites ✓ %{ review_count } reviews ✓ %{ image_count } images ✓ Discover now!',
    ),
    defaultH1: '',
  },

  'topic': {
    defaultTitle: {
      'country_count image_count num_campsites review_count': $pgettext(
        'default text meta tag title',
        'Your Guide to Camping in Europe',
      ),
    },
    defaultDescription: $pgettext(
      'default text meta tag description',
      'Made with ♥ Find the perfect campsite for you with our market leading platform in %{ country_count } countries ✓ %{ num_campsites } campsites ✓ %{ review_count } reviews ✓ %{ image_count } images ✓ Discover now!',
    ),
    defaultH1: $pgettext('default text h1', 'All camping topics'),
  },

  'topic-all': {
    defaultTitle: {
      'country_count image_count num_campsites review_count': $pgettext(
        'default text meta tag title',
        'Your Guide to Camping in Europe',
      ),
    },
    defaultDescription: $pgettext(
      'default text meta tag description',
      'Made with ♥ Find the perfect campsite for you with our market leading platform in %{ country_count } countries ✓ %{ num_campsites } campsites ✓ %{ review_count } reviews ✓ %{ image_count } images ✓ Discover now!',
    ),
    defaultH1: '',
  },
};
