import Cookies from 'js-cookie';

import { COOKIEDOMAIN, USER_LOGIN_TOKEN } from '@/utils/constants';

export const getCookie = (name, options) => Cookies.get(name, options);

export const setCookie = (name, value, options) => {
  if (!options) {
    options = {};
  }
  return Cookies.set(name, value, { sameSite: 'strict', secure: true, ...options });
};

export const deleteCookie = (name, options) => {
  let existing = true;
  try {
    const c = getCookie(name);
    existing = c !== undefined && c !== null;
  } catch (_e) {
    // error is okay
  }
  if (!existing) {
    return;
  }
  try {
    Cookies.remove(name, { sameSite: 'strict', secure: true, ...options });
  } catch (_e) {
    // error is okay
  }
};

export const getUserLoginToken = () => {
  if (COOKIEDOMAIN) {
    return getCookie(USER_LOGIN_TOKEN, { domain: COOKIEDOMAIN });
  }
  return getCookie(USER_LOGIN_TOKEN);
};

export const setUserLoginToken = (token, expires) => {
  if (COOKIEDOMAIN) {
    setCookie(USER_LOGIN_TOKEN, token, { expires, domain: COOKIEDOMAIN });
  } else {
    setCookie(USER_LOGIN_TOKEN, token, { expires });
  }
};

export const removeUserLoginToken = () => {
  if (COOKIEDOMAIN) {
    deleteCookie(USER_LOGIN_TOKEN, { domain: COOKIEDOMAIN });
  } else {
    deleteCookie(USER_LOGIN_TOKEN);
  }
};
