import { defineStore, acceptHMRUpdate } from 'pinia';

export const useLoadingStore = defineStore('loading', () => {
  /********************
   * REFS & VARS       *
   ********************/
  type State = {
    error: Map<string, any>;
    loading: Set<string>;
    success: Set<string>;
  };

  const state = reactive<State>({
    error: new Map(),
    loading: new Set(),
    success: new Set(),
  });

  const isLoading = computed(() => {
    return (name: string) => (name ? state.loading.has(name) : false);
  });

  /********************
   * FUNCTIONS         *
   ********************/
  function setStatusLoading(name: string): void {
    // add to loading
    state.loading.add(name);

    // remove from other
    state.error.delete(name);
    state.success.delete(name);
  }

  function setStatusSuccess(name: string): void {
    // add to success
    state.success.add(name);

    // remove from other
    state.error.delete(name);
    state.loading.delete(name);
  }

  function setStatusError({ name, error }: { name: string; error: any }): void {
    // add to error
    state.error.set(name, error?.data);

    // remove from other
    state.loading.delete(name);
    state.success.delete(name);
  }

  function resetLoadingState(): void {
    state.error.clear();
    state.loading.clear();
    state.success.clear();
  }

  return {
    isLoading,
    resetLoadingState,
    setStatusError,
    setStatusLoading,
    setStatusSuccess,
    state,
  };
});

export type LoadingStore = ReturnType<typeof useLoadingStore>;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLoadingStore, import.meta.hot));
}
