/* eslint-disable */

const GA_CODE = import.meta.env.NUXT_PUBLIC_GA_CODE || null;
const HOTJAR_CODE = import.meta.env.NUXT_PUBLIC_HOTJAR_CODE || null;
const TABOOLA_CODE = import.meta.env.NUXT_PUBLIC_TABOOLA_CODE || null;
const OUTBRAIN_CODE = import.meta.env.NUXT_PUBLIC_OUTBRAIN_CODE || null;
const AWIN_SCRIPT_URL = import.meta.env.NUXT_PUBLIC_AWIN_SCRIPT_URL || null;
const GOOGLE_ADS_CONVERSION_ID = import.meta.env.NUXT_PUBLIC_GOOGLE_ADS_CONVERSION_ID || null;
const FACEBOOK_ID = import.meta.env.NUXT_PUBLIC_FACEBOOK_ID || null;

export const getCurrentURL = (baseurl, route) => {
  let url = decodeURIComponent(`${baseurl}${route.fullPath}`);
  if (url.startsWith('//localhost')) {
    url = `http:${url}`;
  }
  return url;
};

export const loadGoogleAnalytics = () => {
  if (!GA_CODE) {
    return;
  }

  const f = document.getElementsByTagName('script')[0];
  const j = document.createElement('script');
  j.async = true;
  j.src = `https://www.googletagmanager.com/gtag/js?id=${GA_CODE}`;
  f.parentNode.insertBefore(j, f);

  window.gtag('js', new Date());
  window.gtag('config', GA_CODE);
  window.gtag('consent', 'default', {
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    ad_storage: 'granted',
    analytics_storage: 'granted',
  });
  if (GOOGLE_ADS_CONVERSION_ID) {
    window.gtag('config', GOOGLE_ADS_CONVERSION_ID);
  }
};

export const gtagReportConversion = conversionEventId => {
  if (window === undefined || !GOOGLE_ADS_CONVERSION_ID) {
    return;
  }
  window.gtag('event', 'conversion', {
    send_to: `${GOOGLE_ADS_CONVERSION_ID}/${conversionEventId}`,
  });
  return false;
};

export const loadHotjar = () => {
  if (!HOTJAR_CODE) {
    return;
  }

  let code = HOTJAR_CODE;
  if (typeof code === 'string') {
    code = parseInt(code, 10);
  }

  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: code, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
};

export const loadTaboola = () => {
  if (!TABOOLA_CODE || !window) {
    return;
  }

  taboolaPageView();

  !(function (t, f, a, x) {
    if (!document.getElementById(x)) {
      t.async = 1;
      t.src = a;
      t.id = x;
      f.parentNode.insertBefore(t, f);
    }
  })(
    document.createElement('script'),
    document.getElementsByTagName('script')[0],
    `//cdn.taboola.com/libtrc/unip/${TABOOLA_CODE}/tfa.js?`,
    'tb_tfa_script',
  );
};

export const taboolaPageView = () => {
  if (!TABOOLA_CODE || !window) {
    return;
  }
  window._tfa = window._tfa || [];
  window._tfa.push({ notify: 'event', name: 'page_view', id: TABOOLA_CODE });
};

export const loadOutbrain = () => {
  if (!OUTBRAIN_CODE || !window) {
    return;
  }

  !(function (_window, _document) {
    const OB_ADV_ID = OUTBRAIN_CODE;
    if (_window.obApi) {
      const toArray = function (object) {
        return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];
      };
      _window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(toArray(OB_ADV_ID));
      return;
    }
    let api = (_window.obApi = function () {
      api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);
    });
    api.version = '1.1';
    api.loaded = true;
    api.marketerId = OB_ADV_ID;
    api.queue = [];
    let tag = _document.createElement('script');
    tag.async = true;
    tag.src = '//amplify.outbrain.com/cp/obtp.js';
    tag.type = 'text/javascript';
    let script = _document.getElementsByTagName('script')[0];
    script.parentNode.insertBefore(tag, script);
  })(window, document);

  outbrainPageview();
};

export const outbrainPageview = () => {
  if (!OUTBRAIN_CODE || !window) {
    return;
  }
  if (!window.obApi) {
    return;
  }

  obApi('track', 'PAGE_VIEW');
};

export const loadAwin = () => {
  if (!AWIN_SCRIPT_URL || !window || !document?.body) {
    return;
  }

  const j = document.createElement('script');
  j.async = true;
  j.defer = true;
  j.src = AWIN_SCRIPT_URL;
  document.body.appendChild(j);
};

export const loadFacebook = () => {
  if (!FACEBOOK_ID) {
    return;
  }

  (function (f, b, e, v, n, t, s) {
    if (f.fbq) {
      return;
    }
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) {
      f._fbq = n;
    }
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

  fbq('init', FACEBOOK_ID);
  facebookPageview();
};

export const facebookPageview = () => {
  if (!window?.fbq) {
    return;
  }

  window.fbq('track', 'PageView');
};
