export const DISCOUNT_TYPE_CAMPING_CARD = 'campingcard-acsi';

function getChoicesOrDiscounts(availability) {
  if (!availability) {
    return null;
  }
  return availability.choices || availability.discounts || null;
}

export const getDiscountsFromAvailability = (availability, discountTypes) => {
  const av = getChoicesOrDiscounts(availability);

  if (!av) {
    return [];
  }

  if (typeof discountTypes === 'string') {
    discountTypes = [discountTypes];
  }

  return av.filter(d => discountTypes.includes(d.type));
};

export const getFirstDiscountFromAvailability = (availability, discountTypes) => {
  return getDiscountsFromAvailability(availability, discountTypes).pop() || null;
};

export const availabilityHasDiscount = (availability, discountTypes) => {
  return !!getFirstDiscountFromAvailability(availability, discountTypes);
};

export const availabilityHasCampingCardDiscount = (availability) => {
  return availabilityHasDiscount(availability, DISCOUNT_TYPE_CAMPING_CARD);
};
