<script setup lang="ts">
import { onCLS, onINP } from 'web-vitals/attribution';
import { ModalsContainer } from 'vue-final-modal';
import { loadConsentManager } from '@/utils/h5v';

/********************
 * COMPOSITIONS     *
 ********************/
const runtimeConfig = useRuntimeConfig();
const appStore = useAppStore();
useContent();
useSeo();

if (appStore.backendUnavailable) {
  throw createError({ statusCode: 503, message: 'Backend unavailable' });
}

onMounted(() => {
  addINPEvent();
  addCLSEvent();
  if (!runtimeConfig.public.noConsent) {
    loadConsentManager();
  }
});

/********************
 * FUNCTIONS         *
 ********************/
function addINPEvent() {
  onINP(addINPTracking, { reportAllChanges: true });
}

function addCLSEvent() {
  // const clsDiv = document.createElement('div');
  // clsDiv.classList.add('CLSDebugInfo');
  // document.body.appendChild(clsDiv);

  // const cls = clsDiv;

  // const iframe = document.createElement('iframe');
  // iframe.setAttribute('id', 'hj-vis');
  // iframe.setAttribute('name', 'hj-vis');
  // iframe.style.display = 'none';
  // document.body.appendChild(iframe);

  // see: https://www.npmjs.com/package/web-vitals
  onCLS(
    (metric) => {
      // cls.innerHTML = metric.value.toFixed(5);
      // addCLSVisualization(metric);
      addCLSTracking(metric);
      // addHJTracking(metric);
    },
    { reportAllChanges: true },
  );
}

// function addCLSVisualization(metric) {
//   metric.entries.forEach((entry) => {
//     if (entry.sources) {
//       for (const { node } of entry.sources) {
//         if (node instanceof HTMLElement) {
//           const blacklist = ['no-cls-class', 'nuxt-devtools-panel-content', 'review__item'];
//           // don't add to certain elements
//           if (Array.from(node.classList).some(v => blacklist.includes(v))) {
//             return;
//           }

//           node.classList.add('CLSElement1');
//           if (node.classList.contains('CLSElement2')) {
//             node.classList.remove('CLSElement1');
//           } else {
//             node.classList.add('CLSElement1');
//           }
//         }
//       }
//     }
//   });
// }

function addINPTracking({ name, delta, value, id, navigationType, attribution }) {
  window.gtag?.('event', name, {
    value: delta,
    metric_id: id,
    metric_value: value,
    metric_delta: delta,
    metric_navigation_type: navigationType,
    debug_target: attribution?.interactionTarget,
    release: runtimeConfig.public.appVersion,
  });
}

function addCLSTracking({ name, delta, value, id, navigationType, attribution }) {
  window.gtag?.('event', name, {
    value: delta,
    metric_id: id,
    metric_value: value,
    metric_delta: delta,
    metric_navigation_type: navigationType,
    debug_target: attribution?.largestShiftTarget,
    release: runtimeConfig.public.appVersion,
  });
}

// function addHJTracking({ rating }) {
//   if (window.hj && typeof window.hj === 'function') {
//     window.hj('event', `CLS_${rating}`);
//   }
// }
</script>

<template>
  <div>
    <NuxtLoadingIndicator
      color="#54c100"
      :throttle="100"
    />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <!-- modal -->
    <ModalsContainer />
  </div>
</template>

<style></style>
