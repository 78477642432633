import { translateUrl } from '@/utils/i18n';
import { identifyDynamicPage } from '@/utils/routing';

export default defineNuxtRouteMiddleware(async (to) => {
  const { $i18n, $pinia } = useNuxtApp();
  const appStore = useAppStore($pinia);
  const router = useRouter();

  if (to.name) {
    return;
  }

  const dynamicPageInfo = identifyDynamicPage($i18n.locale.value, appStore.routes, to, router);

  if (dynamicPageInfo.redirect) {
    return navigateTo({ path: dynamicPageInfo.path, query: dynamicPageInfo.query }, { redirectCode: 301 });
  }

  const translatedPath = translateUrl(to.path, $i18n.localeCodes.value, $i18n.locale.value);

  if (translatedPath && translatedPath !== to.path) {
    return navigateTo({ path: translatedPath, query: to.query }, { redirectCode: 301 });
  }
  return;
});
