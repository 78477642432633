export const COOKIEDOMAIN = import.meta.env.NUXT_PUBLIC_COOKIE_DOMAIN || '';
export const USER_COOKIE_CONFIG = 'CI_COOKIE_CONFIG';
export const USER_COOKIE_CONFIG_OLD = 'USER_COOKIE_CONFIG';
export const USER_COOKIE_LANGAUGE = 'CI_USER_LANGUAGE';
export const USER_DATA_TOKEN = 'USER_DATA_TOKEN';
export const USER_LOGIN_DEFAULT_COOKIE_LIFETIME = 30;
export const USER_LOGIN_TOKEN = 'USER_LOGIN_TOKEN';

// ROUTE BASE NAMES
export const RBN_ACCOUNT_MEDIA = 'account-media';
export const RBN_ACCOUNT_MEDIA_DETAIL = 'account-media-identifier';
export const RBN_ACCOUNT_PROFILE = 'account-profile';
export const RBN_ACCOUNT_REVIEWS = 'account-reviews';
export const RBN_CAMPSITE_DETAIL = 'campsite-identifier';
export const RBN_CAMPSITE_SEARCH = 'campsite';
export const RBN_CAMPSITE_RATING_WIDGET = 'campsite-identifier-rating-widget';
export const RBN_COUNTRY_SEARCH = 'country-countrySlug';
export const RBN_CITY_SEARCH = 'city-citySlug';
export const RBN_FAVORITES = 'account-favorites';
export const RBN_FEDERAL_STATE_SEARCH = 'country-countrySlug-federalStateSlug';
export const RBN_IMAGE_UPLOAD = 'campsite-identifier-upload-images';
export const RBN_LOGIN = 'account-login';
export const RBN_REGION_SEARCH = 'popularRegion-regionSlug';
export const RBN_SEARCH = 'search';
export const RBN_SEARCH_MAP = 'searchMap';
export const RBN_VIDEO_UPLOAD = 'campsite-identifier-upload-videos';

export const SIMPLE_ROUTE_IDENTIFIERS = {};

SIMPLE_ROUTE_IDENTIFIERS.index = 'home';
SIMPLE_ROUTE_IDENTIFIERS[RBN_ACCOUNT_MEDIA] = 'cpdp';
SIMPLE_ROUTE_IDENTIFIERS[RBN_CAMPSITE_DETAIL] = 'cpdp';
SIMPLE_ROUTE_IDENTIFIERS[RBN_CAMPSITE_SEARCH] = RBN_SEARCH;
SIMPLE_ROUTE_IDENTIFIERS[RBN_CITY_SEARCH] = RBN_SEARCH;
SIMPLE_ROUTE_IDENTIFIERS[RBN_COUNTRY_SEARCH] = RBN_SEARCH;
SIMPLE_ROUTE_IDENTIFIERS[RBN_FEDERAL_STATE_SEARCH] = RBN_SEARCH;
SIMPLE_ROUTE_IDENTIFIERS[RBN_REGION_SEARCH] = RBN_SEARCH;
SIMPLE_ROUTE_IDENTIFIERS[RBN_SEARCH_MAP] = 'map';
