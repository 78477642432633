import { defineStore, acceptHMRUpdate } from 'pinia';

// types
import type { RouteLocation } from 'vue-router';
import type { SearchState } from '@/types';

import {
  RBN_CITY_SEARCH,
  RBN_COUNTRY_SEARCH,
  RBN_FEDERAL_STATE_SEARCH,
  RBN_REGION_SEARCH,
  RBN_SEARCH,
  RBN_SEARCH_MAP,
} from '@/utils/constants';

export const useRouteStore = defineStore('route', () => {
  /********************
   * COMPOSITIONS      *
   ********************/
  const getRouteBaseName = useRouteBaseName();

  /********************
   * REFS & VARS       *
   ********************/
  const ORIGIN_MAPPINGS = {
    campsite: 'serp',
    countrySearch: 'serp',
    default: 'home',
    federalState: 'serp',
    regionSearch: 'serp',
    search: 'serp',
    searchMap: 'map',
  };

  const searchState = ref<SearchState>({
    foundLocation: false,
    isSet: false,
    route: null,
    searchTerm: null,
  });

  const previousRoute = ref<RouteLocation | null>(null);

  const lastSearchRoute = ref<RouteLocation | null>(null);

  const searchOrigin = computed(() => {
    if (!searchState.value.route?.name) {
      return ORIGIN_MAPPINGS.default;
    }

    const origin = ORIGIN_MAPPINGS[searchState.value.route.name];

    if (!origin) {
      return ORIGIN_MAPPINGS.default;
    }

    return origin;
  });

  const foundLocation = computed(() => {
    return searchState.value.foundLocation;
  });

  const searchTerm = computed(() => {
    return searchState.value.searchTerm;
  });

  /********************
   * FUNCTIONS         *
   ********************/
  function setLastSearchRoute(route: RouteLocation) {
    const baseRouteName = getRouteBaseName(route);

    if (!baseRouteName || !route) {
      return;
    }

    const isSearchPage = [
      RBN_CITY_SEARCH,
      RBN_COUNTRY_SEARCH,
      RBN_FEDERAL_STATE_SEARCH,
      RBN_REGION_SEARCH,
      RBN_SEARCH,
      RBN_SEARCH_MAP,
    ].includes(baseRouteName);

    if (isSearchPage) {
      lastSearchRoute.value = route;
    }
  }

  function setSearchState(state: SearchState | null) {
    if (state && searchState.value !== null && searchState.value !== undefined) {
      searchState.value = {
        ...state,
        isSet: true,
      };
    } else {
      searchState.value = {
        isSet: false,
        route: null,
        searchTerm: null,
        foundLocation: false,
      };
    }
  }

  return {
    foundLocation,
    lastSearchRoute,
    previousRoute,
    searchOrigin,
    searchState,
    searchTerm,
    setLastSearchRoute,
    setSearchState,
  };
});

export type RouteStore = ReturnType<typeof useRouteStore>;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRouteStore, import.meta.hot));
}
