import dayjs from '@/utils/day';
import gettext from '@/utils/gettext';

export default defineNuxtPlugin({
  name: 'translate',
  parallel: true,
  async setup(nuxtApp) {
    // day js locale
    dayjs.locale(getDayjsLanguageCode(nuxtApp.$i18n.locale.value));

    // gettext
    const gt = await gettext(nuxtApp.$i18n.locale.value);
    gt.current = nuxtApp.$i18n.locale.value;
    nuxtApp.vueApp.use(gt);

    // global translation helper use e.g. { $getTranslatedWordCampsite } = useNuxtApp();
    function getTranslatedWordCampsite(): string {
      return gt.$pgettext('Just the word "Campsite", with first letter uppercased.', 'Campsite').toString();
    }

    function translationStartsWithWordCampsite(translation: string): boolean {
      return translation?.toLowerCase().trim().startsWith(getTranslatedWordCampsite().toLowerCase());
    }

    function getCampsiteNameWithCampsitePrefix(campsiteName: string): string {
      if (translationStartsWithWordCampsite(campsiteName)) {
        return campsiteName;
      }

      return `${getTranslatedWordCampsite()} ${campsiteName.trim()}`;
    }

    return {
      provide: {
        getCampsiteNameWithCampsitePrefix,
        getTranslatedWordCampsite,
        translationStartsWithWordCampsite,
      },
    };
  },
});
