import { createGettext } from 'vue3-gettext';
import availableLangs from '@/locales/availableLanguages';

export default async function gettext(lang) {
  const languageFile = await import(`../locales/dist/${lang}.json`);

  return createGettext({
    availableLanguages: availableLangs.reduce((accumulator, currentValue) => {
      accumulator[currentValue.code] = currentValue.name;
      return accumulator;
    }, {}),
    silent: true,
    defaultLanguage: lang,
    translations: languageFile,
    provideDirective: false,
    provideComponent: false,
  });
}

export const $gettext = key => key;
export const $pgettext = (ctx, key) => ({ ctx, key });
