export type DeviceCategory = 'Windows Phone' | 'Android' | 'iOS' | 'unknown';
export interface GetDevice {
  category: DeviceCategory;
  isOldMobileDevice: boolean;
  supportsWebGL: boolean;
}

export default defineNuxtPlugin({
  name: 'device',
  parallel: true,
  setup() {
    const userAgent = navigator.userAgent || navigator.vendor;

    function hasImageBitmap(): boolean {
      return !!window?.ImageBitmap;
    }

    function isOldMobileDevice(): boolean {
      if (!hasImageBitmap()) {
        return true;
      }
      const android = userAgent.match(/android\s(\d+)/i);
      if (android) {
        return Number(android[1]) <= 6;
      }

      const ios = userAgent.match(/(?:iPad|iPhone|iPod)\s+OS\s+(\d+)/i);
      if (ios) {
        return Number(ios[1]) <= 11;
      }

      return false;
    }

    function isWebglSupported(): boolean {
      if (window?.WebGLRenderingContext) {
        const canvas = document.createElement('canvas');
        try {
          const context = canvas.getContext('webgl2') || canvas.getContext('webgl');
          if (context && typeof context.getParameter === 'function') {
            return true;
          }
        } catch (_e) {
          // WebGL is supported, but disabled
        }
        return false;
      }
      // WebGL not supported
      return false;
    }

    function getDevice(): GetDevice {
      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return {
          category: 'Windows Phone',
          isOldMobileDevice: true,
          supportsWebGL: false,
        };
      }

      if (/android/i.test(userAgent)) {
        return {
          category: 'Android',
          isOldMobileDevice: isOldMobileDevice(),
          supportsWebGL: isWebglSupported(),
        };
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return {
          category: 'iOS',
          isOldMobileDevice: isOldMobileDevice(),
          supportsWebGL: isWebglSupported(),
        };
      }

      return {
        category: 'unknown',
        isOldMobileDevice: !hasImageBitmap(),
        supportsWebGL: isWebglSupported(),
      };
    }

    return {
      provide: {
        device: getDevice(),
      },
    };
  },
});
