import { equals } from 'ramda';
import { defineStore, acceptHMRUpdate } from 'pinia';

// types
import type { ContentQuery, ContentTypes, SlotType } from '@/types';

// Stores
import { useLoadingStore } from '@/stores/loading';

export const useContentStore = defineStore('content', () => {
  /********************
   * COMPOSITIONS      *
   ********************/
  const contentService = useContentService();
  const { setStatusError, setStatusLoading, setStatusSuccess } = useLoadingStore();

  /********************
   * REFS & VARS       *
   ********************/
  const contents = shallowRef<ContentTypes[]>([]);
  let lastContentQuery: ContentQuery | null = null;

  const getContentsForSlot = computed(() => {
    return (slot: SlotType) => filterContentsBySlot(contents.value, slot);
  });

  const h1 = computed(() => {
    const meta = contents.value.filter(item => item.slot === 'meta');
    return meta?.[0]?.contents?.[0] ? meta[0].contents[0].h1 : null;
  });

  const meta = computed(() => {
    const meta = contents.value.filter(item => item.slot === 'meta');
    return meta?.[0]?.contents?.[0] ? meta[0].contents[0].meta : null;
  });

  /********************
   * FUNCTIONS         *
   ********************/
  async function getContent(params: ContentQuery) {
    // for some urls like `/en/land/italien` the content service triggers twice with the same data
    // as a quick fix, we store last query params and only query service if the have changed.
    if (equals(lastContentQuery, params) && contents.value.length) {
      return;
    }
    setStatusLoading('getContent');

    try {
      const { lang, url } = params;
      lastContentQuery = { url, lang };
      const response = await contentService.getContent({ lang, url });
      contents.value = response;
      setStatusSuccess('getContent');
      return response;
    } catch (error) {
      setStatusError({ name: 'getContent', error });
    }
  }

  function clearContent() {
    contents.value = [];
  }

  function filterContentsBySlot(contents: ContentTypes[], slot: SlotType) {
    const foundContents = contents.find(item => item.slot === slot);
    if (!foundContents) {
      return [];
    }

    return foundContents.contents;
  }

  return {
    clearContent,
    contents,
    getContent,
    getContentsForSlot,
    h1,
    meta,
  };
});

export type ContentStore = ReturnType<typeof useContentStore>;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useContentStore, import.meta.hot));
}
