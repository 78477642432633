import { defineStore, acceptHMRUpdate } from 'pinia';
import { without } from 'ramda';

export const useJsonLdStore = defineStore('jsonLd', () => {
  /********************
   * REFS & VARS       *
   ********************/
  const faqId = shallowRef(null);
  const faqs = shallowRef<any[]>([]);

  const faqJsonLd = computed(() => {
    if (!faqs.value.length) {
      return [];
    }

    const data = [
      {
        type: 'application/ld+json',
        children: JSON.stringify({
          '@context': 'http://schema.org/',
          '@type': 'FAQPage',
          'mainEntity': toRaw(faqs.value),
        }),
      },
    ];

    if (faqId.value !== null) {
      const tmp = JSON.parse(data[0].children);
      tmp['@id'] = toRaw(faqId.value);
      data[0].children = JSON.stringify(tmp);
    }
    return data;
  });

  /********************
   * FUNCTIONS         *
   ********************/
  function addFaqs(params) {
    faqId.value = params.faqId || null;
    faqs.value = [...faqs.value, ...params.items];
  }

  function removeFaqs(data = false) {
    let items = [];

    if (data) {
      items = without(data, faqs.value);
    }

    faqs.value = items;
    faqId.value = null;
  }

  return {
    addFaqs,
    faqId,
    faqJsonLd,
    faqs,
    removeFaqs,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useJsonLdStore, import.meta.hot));
}
