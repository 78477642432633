export default defineNuxtPlugin({
  name: 'touch',
  parallel: true,
  setup() {
    const root = document.documentElement;

    function isTouchDevice(): boolean {
      return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    }

    if (isTouchDevice()) {
      root.classList.remove('no-touch');
    } else if (!root.classList.contains('no-touch')) {
      root.classList.add('no-touch');
    }
  },
});
