import { defineStore, acceptHMRUpdate } from 'pinia';

export const useSeoStore = defineStore('seo', () => {
  /********************
   * REFS & VARS       *
   ********************/
  const alternateTags = shallowRef([]);
  const distance = shallowRef(null);
  const dynamicMetaParam = shallowRef<any>({});
  const indexPage = shallowRef(null);
  const seo = shallowRef({});

  /********************
   * FUNCTIONS         *
   ********************/
  function setDynamicMetaParam(data) {
    if (data.index !== undefined && data.index !== null) {
      indexPage.value = data.index;
      delete data.index;
    } else {
      indexPage.value = null;
    }
    dynamicMetaParam.value = data;
  }

  return {
    alternateTags,
    distance,
    dynamicMetaParam,
    indexPage,
    seo,
    setDynamicMetaParam,
  };
});

export type SeoStore = ReturnType<typeof useSeoStore>;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSeoStore, import.meta.hot));
}
